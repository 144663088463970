<template>
  <ElScrollbar
      id="result-preview-container"
      wrap-class="result-preview-container"
      @scroll="scroll"
      ref="previewContainerScroll"
      :style="{
      height: scrollHeight + 'px',
    }"
  >
    <div
        class="result-preview-html-content result-preview-html-body"
        v-if="isSupportHtmlPreview && pageCountHtmlPreview > 0"
    >
      <div class="page-box" v-if="reRenderDom">
        <div
            v-for="item in pageCountHtmlPreview"
            :key="item"
            :id="'result-preview-page-' + item"
            class="result-preview-page-box"
        >
          <img
              class="page-loading-gif"
              src="@/assets/common/loading.gif"
              alt=""
          />
        </div>
      </div>
    </div>
    <div
        class="result-preview-html-content result-preview-html-body"
        v-else-if="isSupportHtmlPreview && pageCountHtmlPreview === 0"
    >
      <div class="page-box">
        <img class="page-loading-gif" src="@/assets/common/loading.gif" />
      </div>
    </div>
    <div
        class="source-preview-html-content source-preview-html-body"
        v-else-if="!isSupportHtmlPreview"
    >
      <div class="no-support">
        <img
            src="https://ali-file-cdn.iol8.com/img/icon/empty.png"
            class="no-support-img"
        />
        <div class="no-support-text">
          该文件格式暂不支持译文预览
        </div>
      </div>
    </div>
    <div
        class="source-preview-html-content source-preview-html-body"
        v-else-if="isSupportHtmlPreview && pageCountHtmlPreview < 0"
    >
      <div class="no-support">
        <img
            src="https://ali-file-cdn.iol8.com/img/icon/empty.png"
            class="no-support-img"
        />
        <div class="no-support-text">
          该文件预览失败
        </div>
      </div>
    </div>
  </ElScrollbar>
</template>

<script>
import buildCssText1 from "../buildCss/buildCss1.js";
import buildCssText2 from "../buildCss/buildCss2.js";
import { useI18n } from 'vue-i18n'
import {
  computed,
  nextTick,
  onMounted,
  reactive,
  watch,
  toRefs,
  ref,
  onBeforeUnmount,
} from 'vue'
import { useStore } from 'vuex'
import useUtils from '@/plugins/utils'
import { getPreviewInfo, getSentTarget } from '@/http/api.ts'
import useProject from '@/components/source/useProject'
import axios from 'axios'
import mitt from '@/plugins/bus'
import filterString from "@/plugins/filterString";
export default {
  name: 'wordPreview',
  props: ['tabsIndex', 'scrollHeight'],
  setup(props) {
    const store = useStore()
    const { t } = useI18n()

    //译文是否是特殊语种，原文无需处理
    const isSpecialLanguageText= computed(()=>{
      return store.state.specialLanguageList.includes(store.state.taskDetail.targetLangId)
    })

    const checkedDetail = computed(() => {
      return store.state.checkedDetail
    })
    const state = reactive({
      isRenderPage: {},
      reRenderDom: true,
      isSupportHtmlPreview: true,
      pageCountHtmlPreview: 0,
      docId: '',
    })

    const previewContainerScroll = ref(null)

    const updatePreviewContainerScroll = () => {
      previewContainerScroll.value.update()
    }

    //键值对
    let wordKeyValueObject = {
      htmlPrefixUrl: '',
      pageToSentence: '',
      sentenceToPage: '',
    }

    //节流
    const _throttle = (fn, wait) => {
      let timeout = null
      return (...args) => {
        clearTimeout(timeout)
        timeout = setTimeout(() => {
          fn.apply(this, args)
        }, wait)
      }
    }

    const getShadowPageDom = (sentIndex) => {
      return document.getElementById(
          `result-preview-page-${wordKeyValueObject.sentenceToPage[sentIndex]}`
      )?.shadowRoot
    }

    const scrollIntoView = (n) => {
      //只会匹配第一个
      let shadowDom = getShadowPageDom(n)
      let id = `.result-preview-sent-${n}`
      if (!shadowDom) {
        return
      }
      let sentNode = shadowDom.querySelectorAll(`${id}`)[1]

      if (!sentNode) {
        return
      }

      // console.dir(sentNode);

      let childNode =
          sentNode.getElementsByTagName('span')[0] ||
          sentNode.getElementsByTagName('tspan')[0]

      if (isParentNodeSVG(sentNode)) {
        childNode = sentNode.getElementsByTagName('tspan')[0]
      }

      let container = document.querySelector(`.result-preview-container`)

      container.scrollTop = 0

      const { left, top, height } = container.getBoundingClientRect()
      const {
        left: childLeft,
        top: childTop,
      } = childNode.getBoundingClientRect()

      const diffY = Math.abs(top - childTop)
      if (diffY < height / 2) {
        container.scrollTop = 0
      } else {
        container.scrollTop += diffY - height / 2
      }
    }

    const clearIsFocusSentShadow = (oldSentId) => {
      let oldShadowDomRoot = getShadowPageDom(oldSentId)
      if (oldShadowDomRoot) {
        let oldShadowDomRoot = getShadowPageDom(oldSentId)
        const docHtmlContentSentIdFocus = oldShadowDomRoot.querySelectorAll(
            `.is-focus`
        )
        docHtmlContentSentIdFocus.forEach((item) => {
          item.classList.remove('is-focus')
        })
      }
    }

    const setIsFocusSentShadow = (sentId) => {
      console.log(sentId);
      let shadowDomRoot = getShadowPageDom(sentId)
      if (shadowDomRoot) {
        let sentStr = `.result-preview-sent-${sentId}`
        const shadowDomRootSentArr = shadowDomRoot.querySelectorAll(
            `${sentStr}`
        )

        console.log(shadowDomRootSentArr);

        shadowDomRootSentArr.forEach((item) => {
          item.getElementsByTagName('span').forEach((item) => {
            item.classList.add('is-focus')
          })
        })
      }
    }

    //设置原文选中
    const setNewWordSentShadow = (sentId, oldSentId = 0) => {
      if (oldSentId) {
        clearIsFocusSentShadow(oldSentId)
      }

      setIsFocusSentShadow(sentId)
    }

    const shadowScrollIntoView = (n, oldn = 0) => {
      nextTick(() => {
        setNewWordSentShadow(n, oldn)
        scrollIntoView(n)
      })
    }

    const isParentNodeSVG=(node)=>{
      if (node.ownerSVGElement && node.ownerSVGElement.nodeName == 'svg') {
        return true
      } else {
        return false
      }
    }


    const scrollLoadSentence = (ids = []) => {
      // console.log(ids);
      for (let sentIndex in ids) {
        let shadowDom = getShadowPageDom(sentIndex)
        if (!shadowDom) {
          return
        }
        let domList = shadowDom.querySelectorAll(
            `.result-preview-sent-${sentIndex}`
        )
        let dom = null
        domList.forEach((citem) => {
          citem.classList.forEach((sitem) => {
            if (sitem == 'ts-target') {
              dom = citem
            }
          })
        })

        //判断是否是svg下的文字；
        let isSvg = isParentNodeSVG(dom);

        // console.log(isSvg);
        if (dom && dom.childNodes) {
          dom.classList.add(`target-language-text-${isSpecialLanguageText.value?2:1}`)
          for (let i = 0; i < dom.childNodes.length; i++) {
            //如果isSvg为true 则增加一个节点
            if (dom.childNodes[i] && dom.childNodes[i].nodeType == 1) {
              //开始改造遍历循环组成新值 带上标签；
              // let allTarget =
              let indexDomChild = dom.childNodes[i];
              if (isSvg) {
                let pNode = document.createElement("p");
                pNode.classList.add("svgText");
                dom.appendChild(pNode);
                indexDomChild = pNode;
              }

              let cids = ids[sentIndex]
              let allTarget = ''
              cids.forEach((item) => {
                let text = window.$assignValueToSrc(item.targetPlaceholder || "");
                let itemTarget = text.replaceAll(
                    '<img',
                    `<img style="height:16px;width:16px"`
                )
                itemTarget = `<span data-type="${item.targetPlaceholder?'target':'source'}" class="result-preview-target-${item.sentId} result-preview-target-${item.sentIndex} target-language-text-style">${itemTarget}</span>`
                allTarget = allTarget + itemTarget
              })
              indexDomChild.innerHTML = allTarget;
              if (isSvg) {
                dom.childNodes[i].innerHTML = filterString.getPureString(allTarget);
              }
              break
            }
          }
        }


        //如果非闪语种直接retrun 即可下方不需要执行
        // if (!isSpecialLanguageText) {
        //   return;
        // }

        //如果不存在 ids[sentIndex] 是个数组 判断是否有译文
        let isNotEmptyTarget = ids[sentIndex].some(item=>{
          return item.targetPlaceholder
        })

        //如果是空译文，则屏蔽译文节点，打开原文节点；
        if (!isNotEmptyTarget) {
          let sourceDom = null;
          domList.forEach((citem) => {
            citem.classList.forEach((sitem) => {
              if (sitem == 'ts-source') {
                sourceDom = citem
              }
            })
          })
          //打开原文展示，屏蔽译文
          if (sourceDom && dom) {
            // dom.style
            sourceDom.style.display='inline-block';
            dom.style.display="none";
          }
        }

      }
    }

    const scrollLoadPage = (
        page = [1],
        sentenceIndex = null,
        pageIndex = null
    ) => {
      page.forEach((item) => {
        axios
            .get(`${wordKeyValueObject.htmlPrefixUrl}-p${item}.html`)
            .then((res) => {
              let reg = /<body[^>]*>([\s\S]+?)<\/body>/i
              let htmlStr = reg.exec(res.data)[1]
              if (!htmlStr) {
                return
              }
              let frag = document
                  .createRange()
                  .createContextualFragment(htmlStr)
              let linkStr = res.data.match(/<link[^>]*>/g)
              let linkFrag = document
                  .createRange()
                  .createContextualFragment(linkStr[0])
              // document.body.appendChild(linkFrag);
              //将页码改进 替换下方 将这个函数封装成方法
              let localDom = document.getElementById(
                  `result-preview-page-${item}`
              )
              localDom.innerHTML = ''
              let root = localDom.attachShadow({ mode: 'open' })
              root.appendChild(linkFrag)
              // ts-target //需要判断是否是闪语
              let style = ``
              //如果是闪语增加译文样式
              if (store.state.specialLanguageList.includes(store.state.taskDetail.targetLangId)) {
                style = `.is-focus {
                              background: #fff0d2 !important;
                            }
                            .ts-source{
                                display: none;
                            }
                            .ts-target{
                               direction: rtl;
                               text-align: right;
                            }
                            ${buildCssText2()}
                          `
              } else {
                style = `.is-focus {
                              background: #fff0d2 !important;
                            }
                            .ts-source{
                                display: none;
                            }
                            a {
                              pointer-events: none;
                            }
                            ${buildCssText1()}
                          `
              }
              let ele = document.createElement('style')
              ele.innerHTML = style
              root.appendChild(ele)
              root.appendChild(frag)
              setTimeout(() => {
                let pageSectionDom = root.querySelectorAll(`img`)
                pageSectionDom.forEach((sitem) => {
                  let clientWidth = sitem.clientWidth
                  if (clientWidth > 1000) {
                    let zoomNum = 1000 / clientWidth.toFixed(2)
                    sitem.style.transform = `scale(${zoomNum})`
                    sitem.style.transformOrigin = `0 0`
                  }
                })
              }, 0)
              state.isRenderPage[item] = true
              let params = {
                docId: state.docId,
                //获取当前页有哪些句子；然后把句子id调用接口获取译文进行填充；
                sentIndexes: wordKeyValueObject.pageToSentence[item].join(','),
              }
              getSentTarget(params).then((res) => {
                nextTick(() => {
                  scrollLoadSentence(res.data.data)
                  if (sentenceIndex) {
                    nextTick(() => {
                      shadowScrollIntoView(sentenceIndex)
                    })
                  }
                  if (pageIndex) {
                    nextTick(() => {
                      scrollIntoView(
                          wordKeyValueObject.pageToSentence[pageIndex][0]
                      )
                    })
                  }
                })
              })
            })
      })
    }

    const scroll = _throttle(() => {
      if (!state.isSupportHtmlPreview || state.pageCountHtmlPreview < 1) {
        return
      }

      //滚动加载
      let localShowIndex = []
      let previewDom = document.getElementsByClassName(
          'result-preview-container'
      )[0]
      let scrollTop = previewDom.scrollTop
      let clientHeight = previewDom.clientHeight
      // for (let i = 1; i < pageCount.value + 1; i++) {
      //   let previewPageDom = document.getElementById(
      //     `result-preview-page-${i}`
      //   );
      //   let previewPageDomClientHeight = previewPageDom.clientHeight;
      //   let offsetTop = previewPageDom.offsetTop;
      //
      //   if (previewPageDom.innerHTML.indexOf("page-loading-gif") < 0) {
      //     continue;
      //   }
      //
      //   if (
      //     offsetTop + previewPageDomClientHeight > scrollTop &&
      //     offsetTop + previewPageDomClientHeight < scrollTop + clientHeight
      //   ) {
      //     localShowIndex.push(i);
      //   }
      // }
      for (let i = 1; i < state.pageCountHtmlPreview + 1; i++) {
        let previewPageDom = document.getElementById(
            `result-preview-page-${i}`
        )
        let previewPageDomClientHeight = previewPageDom.clientHeight
        let offsetTop = previewPageDom.offsetTop

        if (previewPageDom.innerHTML.indexOf('page-loading-gif') < 0) {
          continue
        }

        if (
            offsetTop + previewPageDomClientHeight > scrollTop &&
            offsetTop + previewPageDomClientHeight < scrollTop + clientHeight
        ) {
          localShowIndex.push(i)
        }
      }

      let difference = localShowIndex.slice(0, 1) //需要加载的页面也就是当前滚动目的地； 只保留当前页以及上下两页其余清除 (看需不需要做)；

      // 根据差集来重新渲染dom
      if (difference.length) {
        let keysArr = Object.keys(state.isRenderPage)
        if (keysArr.length > 7) {
          state.reRenderDom = false
          state.isRenderPage = {}
          nextTick(() => {
            state.reRenderDom = true
            nextTick(() => {
              scrollLoadPage(difference, null, difference[0])
            })
          })
        } else {
          scrollLoadPage(difference)
        }
      }
    }, 1000)

    const operateUpdateSentence = (e) => {
      e.forEach((item) => {
        //需要判断docId 是否对应；
        if (item.docId == state.docId) {
          let sentIndex = item.sentIndex
          let shadowDom = getShadowPageDom(sentIndex)
          if (!shadowDom) {
            return
          }
          let domList = shadowDom.querySelectorAll(
              `.result-preview-target-${item.sentId}`
          )
          domList.forEach((citem) => {
            //将节点内容改为译文
            citem.dataset.type = item.targetPlaceholder ? 'target' : 'source';
            let text = item.targetPlaceholder || "";
            citem.innerHTML = text.replaceAll(
                '<img',
                `<img style="height:16px"`
            )

            //说明是svg节点
            if (citem.parentNode.nodeName == "P" && citem.parentNode.className.indexOf('svgText')>-1) {
              //需要更新原tspan下的译文
              let sentParentNode = citem.parentNode.parentNode;
              for (let i = 0;i<sentParentNode.childNodes.length;i++) {
                let item = sentParentNode.childNodes[i];
                if (item && item.nodeType==1) {
                  item.innerHTML =filterString.getPureString(citem.parentNode.innerHTML)
                  break
                }
              }
            }

          });


          let previewDomList = shadowDom.querySelectorAll(
              `.result-preview-sent-${item.sentIndex}`
          )


          //获取原译文节点dom
          let previewSourceDom = null;
          let previewTargetDom = null;

          previewDomList.forEach((citem) => {
            citem.classList.forEach((sitem) => {
              if (sitem == 'ts-source') {
                previewSourceDom = citem
              }
            })
          })

          previewDomList.forEach((citem) => {
            citem.classList.forEach((sitem) => {
              if (sitem == 'ts-target') {
                previewTargetDom = citem
              }
            })
          })

          //不要用接口，查询此句话下的所有译文节点；译文节点是被填充了原文的；
          let targetDomList = shadowDom.querySelectorAll(`.result-preview-target-${item.sentIndex}`);
          let isNotEmpty = false;
          targetDomList.forEach((citem)=>{
            //查看是否有其他节点还有译文
            if (citem.dataset.type=='target') {
              isNotEmpty = true;
            }
          })

          //如果不是空译文，无需做处理
          if (previewSourceDom && previewTargetDom) {
            if (isNotEmpty) {
              previewSourceDom.style.display = "none";
              previewTargetDom.style.display = "inline-block";
            } else {
              previewSourceDom.style.display = "inline-block";
              previewTargetDom.style.display = "none";
            }
          }

        }
      })
    }

    const updateSentIndexDom=(list)=>{
      let params = {
        docId: state.docId,
        //获取当前页有哪些句子；然后把句子id调用接口获取译文进行填充；
        sentIndexes: list.join(','),
      }
      getSentTarget(params).then((res) => {
        nextTick(() => {
          scrollLoadSentence(res.data.data)
        })
      })
    }

    //直接更新句子译文
    mitt.on('wordPreviewRenew', (e) => {
      // console.log(e,"译文预览触发更新")
      // console.log(e);
      //type = 1 是传句子list 直接更新句子 type = 2 是更新句子结构，重新加载译文
      if (e.type==1) {
        operateUpdateSentence(e.list)
      } else {
        // 加载句子
        updateSentIndexDom(e.list)
      }
    })

    onBeforeUnmount(() => {
      //关闭监听
      mitt.off('wordPreviewRenew', () => {})
    })

    const updateSourceWordKeyValueObject = async (docId) => {
      return new Promise(async (resolve, reject) => {
        let result = await getPreviewInfo({ htmlType: 2, docId: docId })
        let {
          isSupportHtmlPreview,
          pageCountHtmlPreview,
          htmlPrefixUrl,
          pageSentList,
        } = result.data.data

        state.isSupportHtmlPreview = isSupportHtmlPreview
        state.pageCountHtmlPreview = pageCountHtmlPreview
        state.docId = docId

        if (localStorage.getItem(`${docId}-wordKeyValueObject`)) {
          wordKeyValueObject = JSON.parse(
              localStorage.getItem(`${docId}-wordKeyValueObject`)
          )
        } else {
          for (let key in localStorage) {
            //删除对应的key;
            if (key.indexOf('-wordKeyValueObject') > -1) {
              localStorage.removeItem(key)
              break
            }
          }
          let sentenceToPage = {}
          let pageToSentence = {}
          pageSentList.forEach((item) => {
            pageToSentence[item.page] = item.sentenceIds
            item.sentenceIds.forEach((citem) => {
              sentenceToPage[citem] = item.page
            })
          })
          wordKeyValueObject = {
            sentenceToPage,
            pageToSentence,
            htmlPrefixUrl,
          }
          localStorage.setItem(
              `${docId}-wordKeyValueObject`,
              JSON.stringify(wordKeyValueObject)
          )
        }
        resolve(true)
      })
    }

    onMounted(() => {
      if (checkedDetail.value.sentId) {
        updateSourceWordKeyValueObject(checkedDetail.value.docId).then(
            (res) => {
              nextTick(() => {
                scroll()
              })
            }
        )
      }
    })

    const checkedDetailOnChange = (newDetail, oldDetail) => {
      if (!state.isSupportHtmlPreview || !state.pageCountHtmlPreview) {
        return
      }

      let n = newDetail.sentIndex
      let oldn = oldDetail.sentIndex
      if (n) {
        //根据n 来清理dom节点
        let pageIndex = wordKeyValueObject.sentenceToPage[n]
        if (!pageIndex) {
          return
        }
        let pageClass = `result-preview-page-${pageIndex}`
        // console.log(pageClass)
        let dom = document.getElementById(pageClass);
        // console.log(dom);
        if (dom.innerHTML.indexOf('page-loading-gif') > -1) {
          scrollLoadPage([pageIndex], n)
        } else {
          shadowScrollIntoView(n, oldn)
        }
      }
    }

    watch(checkedDetail, (newDetail, oldDetail) => {
      //如果QA结果存在 才执行定位；
      // 根据sentId 利用keyValue 获取页码 找出页码dom 然后判断是否含有渲染class 没有就走传递参数 重新渲染滚动;
      let { docId } = newDetail
      //需要用docId；//需要检查是否存值 以及docId 是否相同。
      if (state.docId == docId) {
        checkedDetailOnChange(newDetail, oldDetail)
      } else {
        //需要等待执行完成。做成promise；
        updateSourceWordKeyValueObject(docId).then((res) => {
          checkedDetailOnChange(newDetail, oldDetail)
        })
      }
    })

    return {
      previewContainerScroll,
      updatePreviewContainerScroll,
      scroll,
      store,
      ...toRefs(state),
      t,
    }
  },
}
</script>

<style scoped lang="less">
#result-preview-container {
  padding: 20px;
  background: #f2f2f2;
  a {
    color: inherit;
  }
  //修改原译对照加载css的样式
  .page-loading-gif {
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);
    height: 250px;
  }
  .result-preview-html-content {
    transform-origin: center;
    //pointer-events: none;
    .page-box {
      //padding: 0 40px;
      margin: 0 auto;
      position: relative;
      //padding: 0 50px;
      background: #fff;
      padding: 10px;
      //width: 1000px;
      box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.05);
      box-sizing: border-box;
      word-break: break-all;
    }
  }
  .result-preview-page-box {
    border: 1px transparent solid;
  }
  .no-support {
    text-align: center;
    padding-top: 60px;
    .no-support-img {
      width: 160px;
      height: 120px;
    }
    .no-support-text {
      color: #999999;
      font-size: 14px;
    }
  }
}
</style>
