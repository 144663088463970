
  import {
    defineComponent,
    reactive,
    toRefs,
    computed,
    getCurrentInstance,
    onMounted,
    onBeforeUnmount,
    watch,
  } from 'vue'
  import { useI18n } from 'vue-i18n' //国际化
  import { message } from 'ant-design-vue'
  import { postReComputePage, searchSent } from '@/http/api'
  import { useStore } from 'vuex'
  import { getFactTargetText, positionSentence } from '@/plugins/comm.js'
  // import useUtils from "@/plugins/utils";
  import useProject from '@/components/source/useProject'
  import filterStrings from '@/plugins/filterString'
  import useUtils from '@/plugins/utils'
  import {
    LeftOutlined,
    ExclamationCircleOutlined,
    StopOutlined,
    RollbackOutlined,
  } from '@ant-design/icons-vue'

  import {
    getHighLightTextHTML,
    highLightText
  } from "@/plugins/range.js"

  export default defineComponent({
    components: {
      RollbackOutlined,
      LeftOutlined,
      ExclamationCircleOutlined,
      StopOutlined,
    },
    setup() {
      const { appContext } = getCurrentInstance()
      const globalProxy = appContext.config.globalProperties
      const store = useStore()
      const { t } = useI18n()
      const state = reactive({
        visible: false,
        taskDetail: store.state.taskDetail,
        beforeKeywords: computed({
          set: (val) => {
            store.commit('changeKey', {
              searchReplaceText: val,
            })
          },
          get: () => store.state.searchReplaceText,
        }),
        afterKeywords: '',
        pageNum: 1,
        activeIndex: 0,
        searchSentList: [] as any,
        total: 0,
        timer: 0 as any,
        loading: false,
        activeKey: '2',
        copyAllSearchList: [],
        ignoreList: [],
        containLockedSentence: false,
        lockListNum: 0,
        ignoreCase: false,
      })

      const transformAfterKeyWords: any = computed(() => {
        return state.afterKeywords
      })
      const transformBeforeKeyWords: any = computed(() => {
        return state.beforeKeywords
      })

      const { docData } = useProject()
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const toSourceTxt = (item: any) => {
        if (store.state.openEditSourceText) {
          return true
        }
        if (store.state.excelProject.ArrMap.has(item.sentId)) {
          positionSentence(item.sentId)
        } else {
          postReComputePage({
            pageSize: store.state.pageSize,
            taskId: store.state.taskDetail.taskId,
            docId: store.state.taskDetail.docId,
            sentId: item.sentId,
          }).then((res) => {
            store.commit('changeKey', {
              pageNum: res.data.data.pageIndex,
            })
            store.commit('excelProject/changeKey', {
              serialNum:'',
              sourceText: '',
              targetText: '',
            })
            store.commit('excelProject/resetFilterValue')
            store
              .dispatch('getSentence', {
                loading: true,
                sentencePosition: item.sentId,
              })
              .then(() => {
                if (store.state.excelProject.ArrMap.has(item.sentId)) {
                  positionSentence(item.sentId)
                } else {
                  message.warning('该句已经被隐藏')
                }
              }) //请求译文
          })
        }
      }

      const showTipModal = () => {
        state.visible = true
      }

      const recovery = (item) => {
        item.isIgnore = false
        let index = state.ignoreList.findIndex((citem) => {
          return citem == item.sentId
        })
        if (index > -1) {
          state.ignoreList.splice(index, 1)
        }
      }
      const ignore = (item) => {
        item.isIgnore = true
        if (state.ignoreList.indexOf(item.sentId) == -1) {
          state.ignoreList.push(item.sentId)
        }
      }

      const getSearchSent = (keyType?) => {
        if (!state.beforeKeywords) {
          state.total = 0
          state.searchSentList = []
          state.activeIndex = 0
          return
        }
        if (!keyType) {
          state.pageNum = 1
        }
        searchSent({
          ignoreCase: !state.ignoreCase,
          pageNum: state.pageNum,
          pageSize: 100,
          wd: transformBeforeKeyWords.value,
          taskId: state.taskDetail.taskId,
          sentenceListSize: store.state.pageSize,
        }).then((res: any) => {
          state.loading = false
          if (res.data.result == '1') {
            state.searchSentList = [] //每次查询清空
            let data = res.data.data
            let list = data.data
            state.total = data.total
            state.lockListNum = data.lock;
            //高亮在前端做；
            if (list && list.length > 0) {
              let filterList = list.filter((citem) => {
                citem.targetPlaceholder = window.$assignValueToSrc(citem.targetPlaceholder)
                // 赋值高亮文本
                console.log(citem.targetPlaceholder);
                citem.highLightTarget = getHighLightTextHTML(citem.targetPlaceholder,state.beforeKeywords);
                return true
              })
              state.searchSentList = filterList

              //如果是由翻页导致的  则忽略列表不清空 ,反而记住每一个页码忽略的进行二次赋值
              if (keyType == 'page') {
                state.searchSentList.forEach((item) => {
                  if (state.ignoreList.indexOf(item.sentId) > -1) {
                    item.isIgnore = true
                  }
                })
              } else {
                state.ignoreList = []
              }
              state.activeIndex = 0
              // setTimeout(() => {
              //   toSourceTxt(state.activeIndex, 0, 1);
              // }, 200);
            }
          }
        })
      }

      const onPageSizeChange = (page) => {
        state.pageNum = page
        getSearchSent('page')
      }

      const onBeforeKeywordsChange = () => {
        state.loading = true
        if (state.timer) {
          clearTimeout(state.timer)
        }
        state.timer = setTimeout(() => {
          // state.pageNum = 1;
          getSearchSent()
        }, 2000)
        // startSearch();
      }

      const handleOk = () => {
        // let ids: Array<number> = [];
        // let ignoreIds: Array<number> = [];
        //全部替换
        // state.searchSentList.forEach((res: any) => {
        //   // ids.push(res.id);
        //   res.repalceTarget = res.highLightTarget.replace(
        //     /<span class="yello-bg">([\s\S]*?)<\/span>/g,
        //     transformAfterKeyWords.value
        //   );
        // });

        store.commit('changeKey', {
          screenLoading: true,
        })
        store.dispatch('replaceTranslation', {
            ignoreCase: !state.ignoreCase,
            ignoreIds: state.ignoreList.toString(),
            preText: transformBeforeKeyWords.value,
            afterText: transformAfterKeyWords.value,
            requestSource: 'REPLACE',
            containLockedSentence: state.containLockedSentence,
          })
          .then(() => {
            onBeforeKeywordsChange()
            store.commit('changeKey', {
              screenLoading: false,
            })
          })
        state.visible = false
      }

      //开始查找
      const startSearch = () => {
        if (!state.beforeKeywords) {
          message.error('搜索内容不为空')
          return false
        }

        onBeforeKeywordsChange()
      }

      const replace = (type: number, index?) => {
        if (!state.beforeKeywords) {
          message.error('请输入要替换的译文')
          return
        }
        if (state.searchSentList.length == 0) {
          message.error('当前并无可以替换的内容')
          return
        }
        if (type === 1) {
          try {
            //单个替换 需要做处理；高亮处替换
            let res = state.searchSentList[index]
            let replaceNode = document.getElementById(`replace-${res.sentId}`);

            while (replaceNode.getElementsByClassName("high-light-text")?.length) {
              let textNode = document.createTextNode(transformAfterKeyWords.value);
              let delNode = replaceNode.getElementsByClassName("high-light-text")[0];
              delNode.parentNode.replaceChild(textNode,delNode);
            }

            let targetDom = window.$getTargetDom(res.sentId)
            if (targetDom) {
              targetDom.innerHTML = replaceNode.innerHTML;
            }
            store.dispatch('sentCommit', {
                docId:res.docId,
                target: filterStrings.getPureString(replaceNode.innerHTML),
                targetPlaceholder:replaceNode.innerHTML,
                requestSource: 'REPLACE',
                sentId: res.sentId,
                sentIndex: res.sentIndex,
              })
            //从列表中移除当前项
            state.total = state.total-1;
            state.searchSentList.splice(index, 1);
          } catch (error) {
            console.log(error)
            message.error(
              '捕捉到保存译文异常状况，请刷新页面后再重试，仍然失败请联系右上角客服'
            )
          }
        } else if (type === 2) {
          if (state.searchSentList.length) {
            showTipModal()
          }
        }
      }

      const closeReplace = () => {
        store.commit('rightToolbar/changeKey', {
          replaceShow: false,
        })
      }

      watch(
        () => state.beforeKeywords,
        () => {
          onBeforeKeywordsChange()
        }
      )

      onBeforeUnmount(() => {
        store.commit('changeKey', {
          searchReplaceText: '',
        })
      })
      onMounted(() => {
        onBeforeKeywordsChange()
        // state.beforeKeywords = store.state.searchReplaceText;
      })

      return {
        onPageSizeChange,
        closeReplace,
        ignore,
        recovery,
        getSearchSent,
        disabled: computed(() => store.state.openEditSourceText),
        showTipModal,
        handleOk,
        t,
        ...toRefs(state),
        startSearch,
        // prevPage,
        // nextPage,
        toSourceTxt,
        // nextSent,
        replace,
        store,
        onBeforeKeywordsChange,
      }
    },
  })
