import { postTwinsCalculate, updateSourceTarget } from '@/http/api'
import store from '@/store'
import mitt from '@/plugins/bus'
import { Decimal } from 'decimal.js'
const state = {
  qaCheckVisible: false,
  tokenErrdialogVisible: false, //token失效弹框
  //重刷新提示错误弹窗
  refreshErrdialogVisible: false,
  //复制原文提示
  copySourceVisible: false,

  //占位符说明弹窗
  placeHolderVisible: false,

  //术语重译说明弹窗 （普通触发一定是先进说明，再进行重译）
  retranslationTermExplain: {
    visible: false,
    termSource: '',
    termTarget: '',
  },
  //查找替换弹窗
  replaceDialogData: {
    visible: false,
    progress: 0,
    total: 0,
    complete: 0,
  },

  //锁定句介绍弹窗
  lockDialogVisible: false,
  //自动支付译力选择弹窗
  autoPayChecked: false,
  // //术语重译弹窗
  autoRecommendChecked: false,
  //企业译力余额不足的弹窗
  balanceNoEnoughVisible: false,
  // retranslationTerm:false,
  PMLimitVisible: false,
  //提醒语料库弹窗提示
  corpusRemindVisible: false,
  //快照介绍弹窗
  saveSnapshotVisible: false,

  //导出设置
  saveAsTxt:false
}
const getters = {}
const mutations = {
  changeKey(state, payload) {
    for (let i in payload) {
      state[i] = null
      state[i] = payload[i]
    }
  },
}
const actions = {
  // inspectCorpusRemind(state,payload){
  //   // if ()
  // }
}

export default {
  namespaced: true,
  actions,
  mutations,
  getters,
  state,
}
