<template>
  <div
    class="add-term-modal"
    :style="{ height: isAddTermDialog ? '174px' : 0 }"
  >
    <div class="add-term-modal-body">
      <div class="add-term-modal-title">
        <div class="title">
          <span>添加术语</span>
          <span>语言：</span>
          <span>
            {{ getLanguageName(taskDetail.sourceLangId) }}-{{
              getLanguageName(taskDetail.targetLangId)
            }}
          </span>
        </div>
        <div class="close" @click="cancel">
          <CloseOutlined></CloseOutlined>
        </div>
      </div>
      <a-row>
        <a-col :span="12">
          <div class="display-flex align-center">
            <span class="label">原文：</span>
            <a-input class="source-language-text-style" placeholder="原文" v-model:value="sourceText" allowClear />
          </div>
        </a-col>
        <a-col :span="12">
          <div class="display-flex align-center">
            <span class="label">译文：</span>
            <a-input class="target-language-text-style" placeholder="译文" v-model:value="targetText" allowClear />
          </div>
        </a-col>
        <a-col :span="24" class="margin-t-10">
          <div class="display-flex align-center">
            <span class="label text-ellipsis">备注：</span>
            <a-input
              :maxlength="500"
              placeholder="非必填，最大不超过500字"
              v-model:value="comment"
              allowClear
            />
          </div>
        </a-col>
      </a-row>
      <div class="footer-button" v-if="isAddTermDialog">
        <div class="left">
          <div>
            <InfoCircleOutlined />
            可对涉及该术语且未经人工处理过的机翻译文，进行重译
          </div>
        </div>
        <div class="right">
          <a-button @click="reTranslationOk" :loading="loading">
            添加并重译
          </a-button>
          <a-button
            type="primary"
            @click="handleOk()"
            class="margin-l-10"
            :loading="loading"
          >
            添加
          </a-button>
        </div>
      </div>
    </div>
    <!--    <a-modal-->
    <!--      v-model:visible="visible"-->
    <!--      title="提示"-->
    <!--      @ok="termHandleOk"-->
    <!--      @cancel="termHandleCancel"-->
    <!--      okText="确认"-->
    <!--      cancelText="取消"-->
    <!--    >-->
    <!--      <div>-->
    <!--        您添加的术语已存在，是否继续添加-->
    <!--      </div>-->
    <!--    </a-modal>-->

    <a-modal
      title="温馨提示"
      :visible="conflictVisible"
      centered
      :width="880"
      :okText="type == 1 ? '确定' : '确定并开始重译'"
      @ok="conflictOk"
      @cancel="conflictCancel"
    >
      <div class="display-flex justify-between select-term-modal">
        <div class="left">
          <div class="left-title">
            <img src="@/assets/warning_1.png" width="32" />
            检测到
            <span class="color-F55C3F">{{ conflictList.length }}</span>
            个词条与当前
            <span class="color-F55C3F">原文相同译文不同</span>
            ：
          </div>
          <div style="background: #F9F9F9;overflow-y: auto">
            <div class="left-content">
              <div class="content-item">
                <label>原文</label>
                <div class="source-language-text-style content-item-text">
                  {{ sourceText }}
                </div>
              </div>
              <div class="content-item">
                <label style="color:#00B4FA">新译文</label>
                <div class="target-language-text-style content-item-text">
                  {{ targetText }}
                </div>
              </div>
              <div
                class="content-item"
                v-for="(item, i) in conflictList"
                :key="i"
              >
                <label>原有译文</label>
                <div class="content-item-text">
                  <div class="target-language-text-style">
                    {{ item.target }}
                  </div>
                  <div class="color-bbb">
                    更新人：{{ item.lastModifyUserName }} 更新时间：{{
                      dateFormat(item.lastModifyTime, 'YYYY-mm-dd HH:MM')
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="right-title">
            <span class="color-F55C3F margin-r-4">*</span>
            处理方式
          </div>
          <div class="right-content">
            <a-radio-group v-model:value="repeatReplaceMode">
              <div class="content-item">
                <div class="margin-r-2">
                  <a-radio value="source_target_skip"></a-radio>
                </div>
                <div>
                  <div class="color-333 font-size-14">并存</div>
                  <div class="color-999 font-size-13">仍添加为新词条</div>
                </div>
              </div>
              <div class="content-item">
                <div class="margin-r-2">
                  <a-radio value="source_replace"></a-radio>
                </div>
                <div>
                  <div class="color-333 font-size-14">覆盖</div>
                  <div class="color-999 font-size-13">
                    替换原有译文，
                    <span class="color-F55C3F">谨慎操作！</span>
                  </div>
                </div>
              </div>
              <div class="content-item">
                <div class="margin-r-2">
                  <a-radio value="source_skip"></a-radio>
                </div>
                <div>
                  <div class="color-333 font-size-14">不导入</div>
                  <div class="color-999 font-size-13">保持原有译文</div>
                </div>
              </div>
            </a-radio-group>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
  import useUtils from '@/plugins/utils'
  import { useI18n } from 'vue-i18n'
  import { computed, reactive, toRefs, watch } from 'vue'
  import { CloseOutlined, InfoCircleOutlined } from '@ant-design/icons-vue'
  import { useStore } from 'vuex'
  import { message } from 'ant-design-vue'
  import { entryCreate, postCheckAndGetConflict } from '@/http/api'
  import mitt from '@/plugins/bus'

  export default {
    name: 'index.vue',

    components: {
      CloseOutlined,
      InfoCircleOutlined,
    },
    setup() {
      const { getLanguageName, dateFormat } = useUtils()
      const { t } = useI18n()
      const store = useStore()
      const state = reactive({
        conflictVisible: false,
        repeatReplaceMode: 'source_target_skip',
        type: 1,
        comment: '',
        visible: false,
        loading: false,
        conflictList: [],
      })

      const sourceText = computed({
        get() {
          return store.state.rangeText.sourceText
        },
        set(val) {
          let targetText = store.state.rangeText.targetText
          store.commit('changeKey', {
            rangeText: {
              sourceText: val,
              targetText: targetText,
            },
          })
        },
      })

      const targetText = computed({
        get() {
          return store.state.rangeText.targetText
        },
        set(val) {
          let sourceText = store.state.rangeText.sourceText
          store.commit('changeKey', {
            rangeText: {
              sourceText: sourceText,
              targetText: val,
            },
          })
        },
      })
      const taskDetail = computed(() => {
        return store.state.taskDetail
      })
      const checkTwinslatorMatch = () => {
        setTimeout(() => {
          store.dispatch('excelProject/twinslatorMatch', {
            sentId: store.state.checkedDetail.sentId,
          })
        }, 1000)
      }

      //关闭添加术语弹窗
      const cancel = () => {
        store.commit('changeKey', {
          isAddTermDialog: false,
          rangeText: {},
        })
        sourceText.value = ''
        targetText.value = ''
        state.comment = ''
      }

      //开始术语重译
      const createReTranslation = () => {
        if (state.repeatReplaceMode == 'source_skip') {
          store.commit('modal/changeKey', {
            retranslationTermExplain: {
              visible: true,
              termSource: sourceText.value,
              termTarget: state.conflictList[0].target,
            },
          })
        } else {
          store.commit('modal/changeKey', {
            retranslationTermExplain: {
              visible: true,
              termSource: sourceText.value,
              termTarget: targetText.value,
            },
          })
        }
      }

      //添加术语
      const createAddTerm = () => {
        entryCreate({
          groupId: taskDetail.value.groupId,
          libraryId: taskDetail.value.termLibrary[0].libraryId,
          libType: 1,
          source: sourceText.value,
          target: targetText.value,
          comment: state.comment,
          isAudit: 0,
          docId: taskDetail.value.docId,
          sentId: store.state.checkedDetail.sentId,
          repeatReplaceMode: state.repeatReplaceMode,
        })
          .then((res) => {
            //特殊处理
            if (res.data.result == '1') {
              message.success('术语添加成功')
              checkTwinslatorMatch()

              if (state.type == 2) {
                createReTranslation()
              }

              return
            }
            // state.visible=true;
          })
          .finally(() => {
            //清空冲突处理的值
            conflictCancel()
            //清空关闭弹窗的值
            cancel()
          })
      }

      //冲突处理确认弹窗
      const conflictOk = () => {
        if (state.repeatReplaceMode == 'source_skip') {
          //类型1 添加术语
          if (state.type == 1) {
          } else {
            //类型2 添加术语并重译
            createReTranslation()
          }
          //全部关闭
          conflictCancel()
          cancel()
          return
        }
        //添加术语
        createAddTerm()
      }

      //冲突处理取消弹窗
      const conflictCancel = () => {
        //重置冲突值
        state.conflictList = []
        state.conflictVisible = false
        localStorage.setItem('repeatReplaceMode', state.repeatReplaceMode)
      }

      const handleOk = async (type = 1) => {
        if (!sourceText.value) {
          message.error('原文不能为空')
          return false
        }
        if (!targetText.value) {
          message.error('译文不能为空')
          return false
        }

        state.type = type
        state.loading = true

        // //根据判断是否重复的接口出二次选择弹窗
        let result = await postCheckAndGetConflict({
          libraryId: store.state.taskDetail.termLibrary[0].libraryId,
          source: sourceText.value,
          target: targetText.value,
        })
        // //直接关闭
        state.loading = false
        if (!result) {
          return
        }
        // console.log(result);

        state.conflictList = [...result.data.data]
        if (state.conflictList?.length) {
          state.repeatReplaceMode =
            localStorage.getItem('repeatReplaceMode') || 'source_target_skip'
          state.conflictVisible = true
          return
        }
        createAddTerm()
      }

      //添加术语并重译
      const reTranslationOk = async () => {
        if (!store.state.taskDetail.isOpenTwinslator) {
          message.warning('项目经理已关闭孪生译员，暂无法使用哦！')
          return
        }
        if (!store.state.taskDetail.newTwinslatorId  || store.state.taskDetail.newTwinslatorId<0) {
          message.warning(
            '当前并未设置机翻引擎，无法进行术语重译功能，请先在翻译设置中设置引擎'
          )
          return
        }
        handleOk(2)
      }

      const isAddTermDialog = computed({
        set: (val) => {
          store.commit('changeKey', {
            isAddTermDialog: val,
          })
        },
        get: () => store.state.isAddTermDialog,
      })

      return {
        conflictOk,
        conflictCancel,
        dateFormat,
        sourceText,
        targetText,
        reTranslationOk,
        ...toRefs(state),
        taskDetail,
        t,
        getLanguageName,
        isAddTermDialog,
        handleOk,
        cancel,
      }
    },
  }
</script>

<style scoped lang="scss">
  .select-term-modal {
    .left {
      flex-grow: 1;
      margin-right: 20px;
      padding-right: 20px;
      border-right: 1px solid #eeeeee;
      .left-title {
        margin-bottom: 25px;
        img {
          margin-right: 12px;
        }
      }
      .left-content {
        border-radius: 8px;
        padding: 0 20px;
        .content-item {
          padding: 12px 0;
          border-bottom: 1px solid #eeeeee;
          display: flex;
          &:last-child {
            border-color: transparent;
          }
          .content-item-text{
            width: 0;
            flex-grow: 1;
            text-align: right;
            direction: rtl;
          }
          label {
            font-weight: bold;
            font-size: 13px;
            color: #333333;
            flex-shrink: 0;
            flex-basis: 120px;
          }
        }
      }
    }
    .right {
      flex-shrink: 0;
      flex-basis: 200px;
      .right-title {
        font-weight: bold;
        margin-bottom: 16px;
      }
      .right-content {
        .content-item {
          display: flex;
          align-items: center;
          margin-bottom: 16px;
        }
      }
    }
  }

  .add-term-modal {
    box-sizing: border-box;
    width: 700px;
    position: fixed;
    transform: translate(-50%, 0);
    height: 0;
    transition: height 0.3s;
    left: 50%;
    z-index: 99;
    top: 0;
    box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.1);
    overflow: hidden;

    .add-term-modal-body {
      padding: 10px 20px;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      background: white;
    }

    .add-term-modal-title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 14px;
      line-height: 22px;
      height: 22px;

      .title {
        //cursor: move;
        flex-grow: 1;

        span:nth-child(1) {
          font-size: 16px;
          font-weight: 500;
          color: #333333;
          margin-right: 10px;
        }

        span:nth-child(2) {
          color: #999999;
          font-size: 14px;
        }

        span:nth-child(3) {
          color: #999999;
          font-size: 14px;
        }
      }

      .close {
        cursor: pointer;
      }

      //text-align: center;
    }

    .label {
      display: inline-block;
      min-width: 80px;
      text-align: right;
    }

    .footer-button {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left {
        color: #666666;
        font-size: 12px;
        text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
      }
    }
  }
</style>
