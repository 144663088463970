import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import Project from '@/views/Project.vue'
import Preview from "@/views/Preview.vue"

const routes: Array<RouteRecordRaw> = [
  //项目页面
  {
    path: '/project',
    name: 'Project',
    component: Project,
  },
  {
    path: '/Preview',
    name: 'Preview',
    component: Preview,
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

export default router
