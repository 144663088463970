import { MutationTree, ActionTree } from 'vuex'
import mitt from '@/plugins/bus'
import md5 from 'js-md5'
import { message } from 'ant-design-vue'
import {
  getPreviewModelOptionResource,
  getOptionResource,
  getTaskDetail,
  getLanguageList,
  getIndustryInfoList,
  getTranslateWordCount,
  getSentenceProgress,
  webcatSentenceUnLock,
  sentCommit,
  replaceTranslation,
  getSentence,
  twinslatorMt,
  getSentencePreview,
  allSentenceLogs,
  checkProgress,
  getQAHtml,
  getUserLevelPrivilege,
  getSentTarget,
} from '@/http/api'
import {
  indexDbSetVal,
  indexDbDeleteVal,
  indexDbGetVal,
  indexDbGetAllVal,
} from '@/indexDB/indexDB.js'
import { getQueryString } from '@/plugins/prototype'
import {getFactSourceText, isFocusPositionSource, isFocusPositionTarget} from '@/plugins/comm.js'
import { nextTick } from 'vue'
import filterStrings from '@/plugins/filterString'
import {
  addAutoCommitQueue,
  autoCommit,
  deleteAutoCommit,
  deleteAutoCommitQueue,
} from '@/indexDB/autoCommit.js'
// import router from "@/router/index";
import Any = jasmine.Any
import useUtils from '@/plugins/utils'
const sleepFunc = (time = 800) => {
  return new Promise((resolve: any) => {
    setTimeout(() => {
      resolve()
    }, time)
  })
}

const { setFocusEnd } = useUtils()

export const state = {
  batchDocList:{

  },
  CLIENT_HEIGHT: document.documentElement.clientHeight,
  isHttpsProgress:true,
  screenLoading: false, //全屏加载进度条 ;
  headerOptions: [], //header按钮配置项
  qaProgress: false, //Qa全文检查右下方进度提示开关
  taskDetail: {} as any, //项目详情
  languageList: [], //语种
  industryList: [], //行业
  //特殊语种需要颠倒的闪语
  specialLanguageList:[13,16,39],
  //用户登记和权益
  userLevelPrivilege: {
    // privilege:{
    //   export_format:""
    // }
  },

  //折叠组数据
  collapseGroup:[],


  wordPreviewScale: 1,

  //webpact 企业设置
  settings: {
    engine_show_my_created: true,
    engine_show_my_together: true,
    logo: '',
  },

  //润色等级
  decorationLevelObj: {},


  //提交的队列；
  sentCommitPromise: [],

  skin: 'normal',//皮肤
  size: 'small',//字体

  wordPreviewTabsValue: 1, //预览选中项

  wordCount: {}, //翻译字数

  checkedDetail: {}, //当前选中的信息-source、target、sentId
  fullScreen: false, //原文预览全屏展示

  mistakeList: [], //提交检查错误列表

  pageNum: 1, //当前译文的页数
  pageSize: 200, //当前句子-一页多少条

  //是否连续机翻
  continuedMt: false,

  isAddTermDialog: false, //添加术语弹框visible

  rangeText: {
    //选中的文本-用于新增术语传值
    sourceText: '',
    targetText: '',
  },
  //查找替换的文本 选中赋值
  searchReplaceText: '',

  isWindow: true,
  //查词文本
  searchWordText: '',

  // 充值弹窗
  rechargeVisible: false,
  totalPay: {},


  //引擎评分弹窗
  engineGradeVisible: false,
  //议员评分弹窗
  interpreterEvaluateVisible: false,

  qaList: [], //qa预览列表
  checkProgressDetail: {}, //qa预览进度信息
  qaProgressVisible: false, //qa预览进度弹框

  openEditSourceText: false, //原文编辑模式是否打开。 打开之后译文无法编辑

  isDisabledEnter: false,
}
export const getters = {
  //全局判断属性之一
  //是否为预览模式
  $isPreviewMode(state: any) {
    if (
      state.taskDetail.webcatPattern == 'TranslatorView' ||
      state.taskDetail.webcatPattern == 'RecruitAuditView'
    ) {
      return true
    }
    return false
  },
}

export const mutations: MutationTree<any> = {
  changeKey(state: any, payload: any) {
    for (let i in payload) {
      state[i] = null
      state[i] = payload[i]
    }
  },
}

export const actions = {
  //添加进折叠组
  addCollapseGroup({ commit, rootState, dispatch, state }: any, params: any){
    if (params?.groupParentId) {
      let groupParentId = params.groupParentId;
      if (groupParentId && !rootState.collapseGroup.includes(groupParentId)) {
        rootState.collapseGroup.push(groupParentId);
      }
    } else {
      //全部折叠
      rootState.excelProject.docData.sentenceDTOList.forEach(item=>{
        //是否为首句
        if (item.isGroupFirst) {
          if (item.groupParentId && !rootState.collapseGroup.includes(item.groupParentId)) {
            rootState.collapseGroup.push(item.groupParentId);
          }
        }
      })
    }
    // console.log(params)
    // rootState.collapseGroup = [...new Set(rootState.collapseGroup.push(params.groupParentId))]
  },
  //从折叠组里面移除
  removeCollapseGroup({ commit, rootState, dispatch, state }: any, params: any) {
    if (params?.groupParentId) {
      let index = rootState.collapseGroup.indexOf(params.groupParentId);
      if (index>-1) {
        rootState.collapseGroup.splice(index,1)
      }
    } else {
      rootState.collapseGroup.splice(0, rootState.collapseGroup.length);
    }
  },
  openFindReplace({ commit, rootState, dispatch, state }: any, params: any) {
    let selection = document.getSelection()
    let text = '';

    if (isFocusPositionTarget()) {
      text = selection.toString()
      commit('changeKey', {
        searchReplaceText: text,
      })
    }

    commit(
      'rightToolbar/changeKey',
      {
        replaceShow: true,
      },
      { root: true }
    )
  },
  openSearchWord({ commit, rootState, dispatch, state }: any, params: any) {
    if (isFocusPositionTarget() || isFocusPositionSource()) {
      let text = window.getSelection().toString();
      commit('changeKey', {
        searchWordText: text,
      })
    }

    commit(
      'rightToolbar/changeKey',
      {
        toolbarNav: {
          tbNavActiveIndex: 9,
        },
      },
      { root: true }
    )
  },
  openAddTerm({ commit, rootState, dispatch, state }: any, params: any) {
    let selection = document.getSelection()
    let sourceText = ''
    let targetText = ''
    let isTarget = isFocusPositionTarget();
    let isSource = isFocusPositionSource();


    if (isSource) {
      sourceText = selection.toString()
      commit('changeKey', {
        rangeText: {
          //选中的文本-用于新增术语传值
          sourceText: sourceText,
          targetText: targetText,
        },
      })
    }

    if (isTarget) {
      targetText = selection.toString()
      commit('changeKey', {
        rangeText: {
          //选中的文本-用于新增术语传值
          sourceText: sourceText,
          targetText: targetText,
        },
      })
    }

    commit('changeKey', {
      isAddTermDialog: true,
    })
  },
  //  下方双语预览更新事件 负责 译文提交保存触发，清空译文触发，等等
  wordPreviewRenew({ commit, rootState, dispatch, state }: any, params: any) {
    // console.log(params);
    //入栈
    params.forEach((item) => {
      commit('revokeSentence/pushRevokeList', { ...item })
    })
    mitt.emit('wordPreviewRenew', {
      type:1,
      list:[...params]
    })
  },
  //预览模式获取配置按钮
  getPreviewModelOptionResource({ commit, rootState, dispatch, state }: any) {
    getPreviewModelOptionResource().then((res) => {
      let list = res.data.data.list
      list = list.filter((item) => {
        if (
          item.frontResourceKey == 'HEADER_EXPORT' &&
          !state.taskDetail.allowExport
        ) {
          return false
        }
        if (item.frontResourcePosition == 'HEADER') {
          return true
        } else {
          return false
        }
      })
      // console.log(list)
      commit('changeKey', {
        headerOptions: list,
      })
    })
  },
  //获取配置按钮
  getOptionResource({ commit }: any) {
    getOptionResource().then((res) => {
      let list = res.data.data.list
      list = list.filter((item) => {
        if (
          item.frontResourceKey == 'HEADER_EXPORT' &&
          !state.taskDetail.allowExport
        ) {
          return false
        }
        if (item.frontResourcePosition == 'HEADER') {
          return true
        } else {
          return false
        }
      })
      // console.log(list)
      commit('changeKey', {
        headerOptions: list,
      })
    })
  },
  //获取任务详情信息
  getTaskDetail({ commit, rootState, dispatch, state }: any) {
    return new Promise((resolve, reject) => {
      getTaskDetail()
        .then((res) => {
          let data = res.data.data
          //预览模式
          if (data.taskStatus == 2) {
            data.isOpenTwinslator = false
          }
          if (data.taskClassify == 9 || data.taskClassify == 10 ||  data.taskClassify == 11) {
            //个人任务
            data.isSelfWork = true
          }

          //根据返回的结果决定是否调用获取确认流程的接口；

          commit('changeKey', {
            taskDetail: data,
          })
          dispatch('getTranslateWordCount')
          resolve(data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  //获取语言列表
  getLanguageList({ commit }: any) {
    getLanguageList().then((res) => {
      let list = res.data.data.list
      commit('changeKey', {
        languageList: list,
      })
    })
  },
  //获取行业列表
  getIndustryInfoList({ commit }: any) {
    getIndustryInfoList().then((res) => {
      let list = res.data.data.list
      commit('changeKey', {
        industryList: list,
      })
    })
  },
  //获取翻译进度-字数
  getTranslateWordCount({ commit, rootState }: any) {
    // if ((router.currentRoute as any)._rawValue.name == "corpus") {
    //   return;
    // }
    getTranslateWordCount().then((res) => {
      if (res.data.data.wordCountStatus == 'SUCCESS') {
        commit('changeKey', {
          wordCount: res.data.data,
        })
      }
    })
  },
  //提交译文 现在需要对sentCommit 做防抖节流；
  sentCommit({ commit, rootState, dispatch, state }: any, params: any) {
    return new Promise((resolve, reject) => {
      params.target = filterStrings.getString(params.target)
      //开始入栈
      // params.targetMark = filterStrings.getString(params.targetMark);

      //提交参数
      let paramsObj = Object.assign(
        {
          docId: rootState.taskDetail.docId,
        },
        params
      )
      //加一层保险 过滤标签字段；
      paramsObj.targetPlaceholder = filterStrings.retainImportant(
        paramsObj.targetPlaceholder
      )

      //过滤&nbsp为空格; 不能直接过滤，应该只在提交的时候单独操作，译文不变,但是QA画线以及其他问题
      //分析问题1：如果直接替换，对比译文和Dom上面节点译文不相同，再次点击将会走提交逻辑
      //需要判断提交译文是否有特殊字符

      let excludesArr = [];
      //需要判断是否是srt句子 检测
      if (rootState.batchDocList[params.docId] == 'srt') {
        // e.preventDefault();
        excludesArr = ['\n'];
      }
      let isInclude = filterStrings.escapeCharacter(excludesArr).some((eitem) => {
        return paramsObj.targetPlaceholder.includes(eitem)
      })

      if (isInclude) {
        //开始过滤
        paramsObj.targetPlaceholder = filterStrings.filterEscapeCharacter(
          paramsObj.targetPlaceholder,excludesArr
        )
        paramsObj.target = filterStrings.filterEscapeCharacter(paramsObj.target,excludesArr)
        //同步更新译文
        let targetNode = window.$getTargetDom(paramsObj.sentId);
        //如果Node节点存在则更新译文
        if (targetNode) {
          //执行targetNode
          // console.log(222);
          //问题1 为什么一定要延迟
          setTimeout(() => {
            // console.log(222)
            window.$getTargetDom(paramsObj.sentId).innerHTML = paramsObj.targetPlaceholder;
          }, 0)
        }
      }

      let dbParamsObj = { ...paramsObj }
      // dbParamsObj.saveStatus = 'loading';
      dbParamsObj.updateTargetTime = Date.now()

      if (!paramsObj.onlyUpdateConfirmStatus) {
        let sentenceArr = []
        sentenceArr.push(params)
        dispatch('wordPreviewRenew', sentenceArr)
      }

      let listItem =
        rootState.excelProject.docData.sentenceDTOList[
          rootState.excelProject.ArrMap.get(paramsObj.sentId)
        ]

      if (listItem) {
        if (listItem.commitTimer) {
          clearTimeout(listItem.commitTimer)
        } else {
          //检查paramsObj.onlyUpdateConfirmStatus 这里一定是由enter触发的；
          if (paramsObj.onlyUpdateConfirmStatus || paramsObj.saveFailStatus) {
            listItem.commitConfirm = true
            // listItem.isConfirm = listItem.commitConfirm;
          }
        }
        listItem.commitTimer = setTimeout(() => {
          //如果只是改变译文状态，并且为已确认，则什么都不执行；
          if (paramsObj.onlyUpdateConfirmStatus && listItem.isConfirm) {
          } else {
            //非只改变译文状态的情况 才入库，因为译文状态是不更新译文时间的；
            if (!paramsObj.onlyUpdateConfirmStatus) {
              deleteAutoCommitQueue(dbParamsObj.sentId)
              //存入数据库
              indexDbSetVal(dbParamsObj)
            }
            let index = rootState.sentCommitPromise.length - 1
            //如果此句有之前提交确认；则一段范围内都为确认提交；
            if (listItem.commitConfirm) {
              // console.log(999)
              paramsObj.confirm = listItem.commitConfirm
              // console.log(listItem.isConfirm);
            }
            // console.log(paramsObj.isConfirm);
            let p = sentCommit(paramsObj)
              .then((res: any) => {
                //如果是只改译文状态则不触发下面逻辑
                if (paramsObj.onlyUpdateConfirmStatus) {
                  listItem.isConfirm = true
                  // //同步删除数据库
                  // deleteAutoCommit(dbParamsObj.sentId);
                  return
                }

                //如果接口未成功，则添加到自动提交队列，因为里面一定不是只改变译文状态
                if (!res) {
                  addAutoCommitQueue(dbParamsObj)
                  // autoCommit(dbParamsObj)
                  return
                }

                // console.log(window.autoCommitQueue);

                let result = res.data.data

                dispatch('updateTargetOrStatus', {
                  type: 'commit',
                  sentId: paramsObj.sentId,
                  targetHTML: paramsObj.targetPlaceholder,
                  assignObjItem: {
                    diffCompare: result.diffCompare,
                    isConfirm: result.isConfirm,
                    targetText: result.targetText,
                    targetType: result.targetType,
                  },
                })
                resolve(res)
              })
              .catch((err: any) => {
                addAutoCommitQueue(dbParamsObj)
                reject(err)
              })
              .finally(() => {
                setTimeout(() => {
                  rootState.sentCommitPromise.splice(index, 1)
                }, 200)
              })
            rootState.sentCommitPromise.push(p)
          }
          listItem.commitTimer = 0
          listItem.commitConfirm = false
        }, 400)
      } else {
        //开始进栈
        deleteAutoCommitQueue(dbParamsObj.sentId)
        indexDbSetVal(dbParamsObj)
        let index = rootState.sentCommitPromise.length - 1
        let p = sentCommit(paramsObj)
          .then((res: any) => {
            if (!res) {
              addAutoCommitQueue(dbParamsObj)
              // autoCommit(dbParamsObj);
              return
            }
            let result = res.data.data

            // console.log(window.autoCommitQueue);
            dispatch('updateTargetOrStatus', {
              type: 'commit',
              sentId: paramsObj.sentId,
              targetHTML: paramsObj.targetPlaceholder,
              assignObjItem: {
                diffCompare: result.diffCompare,
                isConfirm: result.isConfirm,
                targetText: result.targetText,
                targetType: result.targetType,
              },
            })
            resolve(res)
          })
          .catch((err: any) => {
            //开始自动存储
            addAutoCommitQueue(dbParamsObj)
            // autoCommit(dbParamsObj);
            reject(err)
          })
          .finally(() => {
            setTimeout(() => {
              rootState.sentCommitPromise.splice(index, 1)
            }, 200)
          })
        rootState.sentCommitPromise.push(p)
      }
    })
  },
  //查找-全部替换
  replaceTranslation({ commit, rootState, dispatch }: any, params: any) {
    return new Promise((resolve, reject) => {
      replaceTranslation(
        Object.assign(
          {
            taskId: state.taskDetail.taskId,
          },
          params
        )
      )
        .then((res: any) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  //所有更新译文都在此处理；要传类别 和 sentId
  updateTargetOrStatus({ commit, dispatch, state }: any, params: any) {
    let ArrMap = state.excelProject.ArrMap
    let sentId = params.sentId
    let sentList = state.excelProject.docData.sentenceDTOList

    let sentItem = sentList[ArrMap.get(sentId)]
    if (!sentItem) {
      return false
    }

    let sentDOM =  window.$getTargetDom(sentItem.sentId);
    let sourceDom = window.$getSourceDom(sentItem.sentId);
    //获取机翻
    if (params.type == 'MT') {
      params.targetHTML = window.$assignValueToSrc(params.targetHTML)
      //获取机翻增加转化
      sentDOM.innerHTML = params.targetHTML;
      sentItem.hasQA = false;
      //一定要在之后赋值比较字段
      sentItem.compareTargetHTML = params.targetHTML;
      sentItem.qaInfoList = [];
      sentItem.qaCheckRules = [];
      deleteAutoCommit(sentId)
      sentItem.saveFailStatus = false
      if (sourceDom.innerHTML.indexOf('qaError') > -1) {
        // console.log(999)
        dispatch('excelProject/renderSourceHtml', { sentId: sentId })
      }
      if (state.taskDetail.taskClassify == 8) {
        //清空PM确认人信息
        let PMConfirmIndex = sentItem.confirmInfos?.findIndex(item=>{
          if (item.operationType == 'PMConfirm') {
            return true
          }
        })
        if (sentItem.confirmInfos[PMConfirmIndex]) {
          sentItem.confirmInfos.splice(PMConfirmIndex,1)
        }
      }
    }

    //清空译文
    else if (params.type == 'Clear') {
      sentItem.targetPlaceholder = params.targetHTML
      sentItem.targetText = params.targetHTML
      sentItem.hasQA = false
      sentItem.isConfirm = false
      sentDOM.innerHTML = params.targetHTML
      sentItem.qaInfoList = []
      sentItem.qaCheckRules = []

      sentItem.diffCompare = params.targetHTML
      sentItem.compareTargetHTML = params.targetHTML
      deleteAutoCommit(sentId)
      sentItem.saveFailStatus = false
      if (sourceDom.innerHTML.indexOf('qaError') > -1) {
        dispatch('excelProject/renderSourceHtml', { sentId: sentId })
        // sourceDom.innerHTML = sentItem.termTagSource || sentItem.sourcePlaceholder;
      }
      //清空PM确认人信息
      if (state.taskDetail.taskClassify == 8) {
        //清空PM确认人信息
        let PMConfirmIndex = sentItem.confirmInfos?.findIndex(item=>{
          if (item.operationType == 'PMConfirm') {
            return true
          }
        })
        if (sentItem.confirmInfos[PMConfirmIndex]) {
          sentItem.confirmInfos.splice(PMConfirmIndex,1)
        }
      }
    }
    //复制原文
    else if (params.type == 'CopySource') {
      sentItem.qaInfoList = []
      sentItem.qaCheckRules = []
      deleteAutoCommit(sentId)
      sentItem.saveFailStatus = false;
      sentItem.targetPlaceholder = params.targetHTML;
      window.$getTargetDom(sentItem.sentId).innerHTML = params.targetHTML;
      //赋值比较字段
      sentItem.diffCompare = params.targetHTML
      sentItem.compareTargetHTML = params.targetHTML
      if (sourceDom.innerHTML.indexOf('qaError') > -1) {
        dispatch('excelProject/renderSourceHtml', { sentId: sentId })
      }
      //清空PM确认人信息
      if (state.taskDetail.taskClassify == 8) {
        //清空PM确认人信息
        let PMConfirmIndex = sentItem.confirmInfos?.findIndex(item=>{
          if (item.operationType == 'PMConfirm') {
            return true
          }
        })
        if (sentItem.confirmInfos[PMConfirmIndex]) {
          sentItem.confirmInfos.splice(PMConfirmIndex,1)
        }
        sentItem.isConfirm = true;
      }
    }
    //QAPage加载
    else if (params.type == 'QA') {
      sentItem.qaInfoList = params.qaLogs || []
      //再赋值
      sentItem.qaCheckRules = params.checkRules || []

      //如果是整页加载且无错误无需处理下方 则只处理原文即可。
      if (sentItem.qaInfoList.length) {
        //此时触发画线操作
        dispatch('excelProject/renderQATargetHtml', { sentId: sentId })
        // sentDOM.innerHTML =  params.targetHTML;
        // setTimeout(()=>{
        //   sentItem.targetEditChange = false;
        // },100)
        //赋值比较字段
        sentItem.compareTargetHTML = sentDOM.innerHTML

        //需要判断当前句QAInfoList 是否为[];分有QA和无QA. 如果有原文QA 则需要划线
        let index = sentItem.qaInfoList.findIndex((item) => {
          return item.direction == 'Source'
        })

        // console.log(sentItem.qaInfoList)

        if (index > -1) {
          //需要重新加载原文
          dispatch('excelProject/renderSourceHtml', { sentId: sentId })
        } else {
          //需要检查原文是否有qaError
          if (sourceDom.getElementsByClassName('qaError').length) {
            //则需要重新加载
            dispatch('excelProject/renderSourceHtml', { sentId: sentId })
          }
        }
      } else {
        let sourceDom = window.$getSourceDom(sentId);
        // let sourceDom = document.getElementById(`source-sent-${sentId}`)
        // console.log(sourceDom);
        if (sourceDom.getElementsByClassName('qaError').length) {
          //则需要重新加载
          dispatch('excelProject/renderSourceHtml', { sentId: sentId })
        }
      }
    }

    //QA推送
    else if (params.type == 'EventQA') {
      //如果打开原文编辑或则差异对比 则停止。
      if (
        state.openEditSourceText ||
        state.excelProject.differentHighLightModel
      ) {
        return
      }
      //先重置
      sentItem.qaInfoList = params.qaLogs || []
      sentItem.qaCheckRules = params.checkRules || []
      //如果有QA
      if (sentItem.qaInfoList.length) {
        //移除译文上所有QA;
        let targetDom = window.$getTargetDom(sentId)
        while (targetDom.getElementsByClassName('qaError').length) {
          let node = targetDom.getElementsByClassName('qaError')[0]
          node.id = ''
          node.className = ''
        }
        //重新赋值新QA;
        dispatch('excelProject/renderQATargetHtml', { sentId: sentId })

        //赋值比较字段
        sentItem.compareTargetHTML = sentDOM.innerHTML

        //判断是否有原文QA
        let index = sentItem.qaInfoList.findIndex((item) => {
          return item.direction == 'Source'
        })
        if (index > -1) {
          //需要重新加载原文
          dispatch('excelProject/renderSourceHtml', { sentId: sentId })
        }
      } else {
        //如果无QA 说明此句无需QA 则原文需要清空当前QA，译文也需要处理;
        let sourceDom = window.$getSourceDom(sentId);
        //检查原文是否有的划线。有划线则取消划线。
        if (sourceDom.getElementsByClassName('qaError').length) {
          //则需要重新加载
          dispatch('excelProject/renderSourceHtml', { sentId: sentId })
        }

        //检查译文是否有的划线。有划线则取消划线。
        let targetDom = window.$getTargetDom(sentId)
        while (targetDom.getElementsByClassName('qaError').length) {
          let node = targetDom.getElementsByClassName('qaError')[0]
          node.id = ''
          node.className = ''
        }
        //赋值
        sentItem.compareTargetHTML = sentDOM.innerHTML
      }
    }
    //提交译文
    else if (params.type == 'commit') {
      // console.log("开始触发自动提交译文的更新");
      sentItem.hasQA = false
      sentItem.saveFailStatus = false
      deleteAutoCommit(sentId)
      //清除QA信息
      sentItem.qaInfoList = []
      sentItem.qaCheckRules = []
      sentItem = Object.assign(sentItem, params.assignObjItem)
      //判断原文有无QA信息。无QA信息保持不变
      if (sourceDom.innerHTML.indexOf('qaError') > -1) {
        dispatch('excelProject/renderSourceHtml', { sentId: sentId })
      }

      while (sentDOM.getElementsByClassName('qaError').length) {
        let node = sentDOM.getElementsByClassName('qaError')[0]
        node.id = ''
        node.className = ''
      }

      //提交重置QA信息;
      sentItem.targetPlaceholder = params.targetHTML
      sentItem.compareTargetHTML = params.targetHTML

      //清空PM确认人信息
      if (state.taskDetail.taskClassify == 8) {
        //清空PM确认人信息
        let PMConfirmIndex = sentItem.confirmInfos?.findIndex(item=>{
          if (item.operationType == 'PMConfirm') {
            return true
          }
        })
        if (sentItem.confirmInfos[PMConfirmIndex]) {
          sentItem.confirmInfos.splice(PMConfirmIndex,1)
        }
      }
    } else {
      console.log('未识别出来的type', params.type)
    }
  },

  //整页加载 分批次；
  getPageQAHTMLResult(
    { commit, dispatch, state, rootState }: any,
    params: any
  ) {
    //查询列表
    if (rootState.rightToolbar.toolbarNav.tbNavActiveIndex == '4') {
      //查询列表
      dispatch('getAllSentenceLogs')
    }
    return new Promise(async (resolve, reject) => {
      if (!params.sentList?.length) {
        resolve(true)
      }
      params = params || {}
      let sentencePosition = params.sentencePosition
      let leftList = []
      let rightList = []
      if (sentencePosition) {
        //则先加载要sentencePosition的位置 找出index位置；
        let index = params.sentList.findIndex((item) => {
          return item == sentencePosition
        })

        if (index > -1) {
          leftList = params.sentList.slice(0, index)
          rightList = params.sentList.slice(index)
          //说明有位置。// 优先加载那一句QA 然后再加载剩下的10其他；
        } else {
          leftList = []
          rightList = params.sentList.slice(0)
        }
      } else {
        leftList = []
        rightList = params.sentList.slice(0)
      }
      let sentList = params.sentList

      //10次一句加载 sentList;向上取整
      let num = Math.ceil(sentList.length / 10)

      //左侧数组反转
      leftList.reverse()
      //右侧按顺序加载

      for (let i = 0; i < num; i++) {
        //单次截取数量
        let sliceLeftList = leftList.splice(0, 10)
        let sliceRightList = rightList.splice(0, 10)
        if (i < num) {
          //不是最后一次的时候，则需要满足相加累积10次；
          if (sliceLeftList.length < sliceRightList.length) {
            let diffLength = sliceRightList.length - sliceLeftList.length
            sliceRightList = sliceRightList.concat(
              rightList.splice(0, diffLength)
            )
          } else {
            let diffLength = sliceLeftList.length - sliceRightList.length
            sliceRightList = sliceRightList.concat(
              rightList.splice(0, diffLength)
            )
          }
        } else {
          sliceLeftList = [...leftList]
          sliceRightList = [...leftList]
        }
        let renderList = sliceRightList.concat(sliceLeftList)

        let data = {
          docId: state.taskDetail.docId,
          sentIds: renderList.join(','),
        }
        if (!renderList?.length) {
          break
        }
        let result = await getQAHtml(data)
        result.data.data.forEach((item) => {
          let ArrMap = state.excelProject.ArrMap
          if (ArrMap.has(item?.sentId)) {
            //比较译文DOMHash节点
            let targetDom = window.$getTargetDom(item.sentId);


            // console.log(md5(targetDom.innerHTML.replace(/(<([^>]+)>)/gi, "")) != item.targetPlaceholderHash && md5(targetDom.innerText) != item.targetHash)
            if (md5(targetDom.innerHTML.replace(/(<([^>]+)>)/gi, "")) != item.targetPlaceholderHash && md5(targetDom.innerText) != item.targetHash) {
              return
            }
            //后端的innerText hash 再某些文字上 与 前端的算的不对 //屏蔽hash 的原因，后端双语文件导入进去的时候，尖括号未转义，导致前端的hash算出来对不上；

            dispatch('updateTargetOrStatus', {
              type: 'QA',
              sentId: item.sentId,
              targetHTML: item.targetQAHtml,
              qaLogs: item.qaLogs,
              checkRules: item.checkRules,
            })

            //加载成功则重新赋值
            // sentenceItem.targetEditChange = false;
            // targetDom.innerHTML =  item.targetQAHtml;
          }
        })
        //则说明是第一次
        if (i == 0) {
          resolve(true)
        }
      }
      // for
      //此处增加原文qa填充 需要维护数据格式 list 每一项增加QA内容 以及 查术语id；qa分批次加载查出
      //进行遍历循环
    })
  },
  //targetQAHtml 译文获取
  getQAHTMLResult({ commit, dispatch, state }: any, params: any) {
    //QA 分段加载
    return new Promise((resolve, reject) => {
      params.sentList.forEach((item) => {
        let ArrMap = state.excelProject.ArrMap
        let sentList = state.excelProject.docData.sentenceDTOList
        if (ArrMap.get(item)) {
          let sentenceItem = sentList[ArrMap.get(item)]
          sentenceItem.hasQA = true
        }
      })
      params = params || {}
      let data = {
        docId: state.taskDetail.docId,
        sentIds: params.sentList.join(','),
      }
      if (!params.sentList?.length) {
        return
      }

      //此处增加原文qa填充 需要维护数据格式 list 每一项增加QA内容 以及 查术语id；qa分批次加载查出
      getQAHtml(data)
        .then((res) => {
          // 直接异步加载
          // console.log(state.checkedDetail);
          // debugger
          res.data.data.forEach((item) => {
            let ArrMap = state.excelProject.ArrMap
            if (ArrMap.has(item.sentId)) {
              let sentList = state.excelProject.docData.sentenceDTOList
              let sentenceItem = sentList[ArrMap.get(item.sentId)] //qa返回的这一句

              //需要将qa信息赋值到对应的item里面去；方便鼠标移入的时候做处理；
              sentenceItem.qaInfoList = []

              //译文DOM
              let targetDom = window.$getTargetDom(item.sentId)

              if (md5(targetDom.innerHTML.replace(/(<([^>]+)>)/gi, "")) !=item.targetPlaceholderHash && md5(targetDom.innerText) != item.targetHash) {
                return
              }

              dispatch('updateTargetOrStatus', {
                sentId: item.sentId,
                targetHTML: item.targetQAHtml,
                checkRules: item.checkRules,
                type: 'EventQA',
                qaLogs: item.qaLogs,
              })
            }
          })
          resolve(res)
        })
        .catch((rej) => {
          reject(rej)
        })
      //进行遍历循环
    })
  },
  //word-项目交付-根据原文sentID获取译文列表
  getSentence(
    { commit, rootState, dispatch, state, getters }: any,
    params: any
  ) {
    return new Promise(async (resolve, reject) => {
      rootState.isHttpsProgress = true;
      const splitIndex = getQueryString('splitIndex')
      let res: any
      params = params || {}
      // params.loading 开启 loading
      if (params.loading) {
        state.screenLoading = true
      }
      const obj = Object.assign(
        {
          groupBy:state.excelProject.groupBy,
          groupSort:state.excelProject.groupSort,
          sourceRegexSearch:state.excelProject.sourceRegexSearch,
          targetRegexSearch:state.excelProject.targetRegexSearch,
          serialNum:state.excelProject.serialNum,
          docId: state.taskDetail.docId,
          pageNum: state.pageNum,
          pageSize: state.pageSize,
          sourceText: state.excelProject.sourceText,
          targetText: state.excelProject.targetText,
          splitIndex,
          //原文内容
          sourceTags: state.excelProject.sourceTags,
          //译文状态
          status: state.excelProject.status,
          //译文来源
          targetFrom: state.excelProject.targetFrom,
          //带标记
          withFlag: state.excelProject.withFlag,
          //质检分数
          qualityScores: state.excelProject.qualityScores,
        },
        params
      )

      let userPattern = state.taskDetail.webcatPattern

      //重置sentCommitPromise 需要判断是否有译文提交操作
      if (userPattern == 'TranslatorView') {
        res = await getSentencePreview(obj)
      } else {
        res = await getSentence(obj)
      }

      // console.log(res);
      rootState.isHttpsProgress = false;
      // if (!res) {
      //   //异常的话取消全局loading
      //   if (params.loading) {
      //     state.screenLoading = false;
      //   }
      //   return
      // }
      //重置
      if (res?.data?.result == 1) {
        commit('revokeSentence/resetRevokeList')
        //获取 有QA错误的 sentId
        let indexKeys = [];
        commit('excelProject/changeKey', {
          checkedList: [],
          checkedAll: false,
        })
        //重置
        commit('optionsBar/changeKey', {
          lastShiftKeyCheckedIndex: -1,
        })
        let data = { ...res.data.data }
        let ArrMap = new Map()
        let indexReduce = 0;

        let firstItem:any = {

        };
        // if (rootState.excelProject.groupBy) {
        //   data.sentenceDTOList.forEach((el,index)=>{
        //     el.tableIndex = index;
        //     if ((el.groupHash && el.groupHash == firstItem.groupHash) || (el.groupUrtHash && el.groupUrtHash == firstItem.groupUrtHash)) {
        //       el.groupParentId = firstItem.groupParentId;
        //       firstItem.groupMemberNumber++
        //     } else {
        //       el.groupParentId = el.sentId;
        //       el.groupMemberNumber=1;
        //
        //       if (firstItem.groupParentId) {
        //         let spliceIndex = firstItem.tableIndex+1;
        //         let sortArr = data.sentenceDTOList.slice(spliceIndex,index).sort(function (a,b){return Number(a.extSentId) - Number(b.extSentId)});
        //         // console.log(sortArr)
        //         if (sortArr.length) {
        //           data.sentenceDTOList.splice(spliceIndex,firstItem.groupMemberNumber-1,...sortArr);
        //         }
        //         el.isGroupFirst = true;
        //         // data.sentenceDTOList[spliceIndex].isGroupFirst = true;
        //       }
        //       firstItem = el;
        //       //是否折叠
        //       if (rootState.excelProject.isCollapsed) {
        //         //折叠的话将groupParentId 加入进去
        //         dispatch("addCollapseGroup",{groupParentId:el.groupParentId});
        //       }
        //     }
        //   })
        // }
        data.sentenceDTOList = data.sentenceDTOList.filter((el: any, index: number) => {
            //如果分组条件被勾上则执行反之不执行
            //如果分组条件被勾上则执行反之不执行
            if (rootState.excelProject.groupBy) {
              if ((el.groupHash && el.groupHash == firstItem.groupHash) || (el.groupUrtHash && el.groupUrtHash == firstItem.groupUrtHash)) {
                el.groupParentId = firstItem.groupParentId;
                firstItem.groupMemberNumber++
              } else {
                el.groupParentId = el.sentId;
                el.isGroupFirst = true;
                el.groupMemberNumber=1;
                firstItem = el;
                //是否折叠
                if (rootState.excelProject.isCollapsed) {
                  //折叠的话将groupParentId 加入进去
                  dispatch("addCollapseGroup",{groupParentId:el.groupParentId});
                }
              }
            }
            el.targetDomId = `target-${el.sentId}`;
            el.sourceDomId = `source-sent-${el.sentId}`;
            //转化双边占位符逻辑，新数据img标签没有src；
            el.sourcePlaceholder = window.$assignValueToSrc(el.sourcePlaceholder);
            el.targetPlaceholder = window.$assignValueToSrc(el.targetPlaceholder);
            el.diffCompare = window.$assignValueToSrc(el.diffCompare);
            el.sourceText = getFactSourceText(el);

            el.tableIndex = index;
            //渲染译文HTML
            el.renderTargetHTML = el.targetPlaceholder
            //赋值比较字段
            el.compareTargetHTML = el.targetPlaceholder;
            //重新赋值
            el.qaInfoList = [];
            //重新清除QA信息
            el.qaCheckRules = []
            if (
              state.taskDetail.webcatPattern != 'AdminEdit' &&
              state.taskDetail.docLocked &&
              el.isLocked &&
              state.taskDetail.taskType == 1
            ) {
              if (el.isTranslatorUnLock) {
                el.locked = false
              } else {
                el.locked = true
              }
            }
            // 原文编辑锁定
            if (state.openEditSourceText) {
              el.sourceLocked = el.locked
            }

            //入栈
            commit('revokeSentence/pushRevokeList', { ...el })

            // 差异对比开启 且差异对比不可以编辑 （后台控制）
            if (state.excelProject.differentHighLightModel && !state.taskDetail.canDifference) {
              el.locked = true
            }

            //判断有无占位符图片
            if (el.sourcePlaceholder.indexOf('<img') > -1) {
              el.hasImg = true
            }

            //设置队列
            ArrMap.set(el.sentId, indexReduce)
            //非原文编辑 以及 原文编辑内部调用的 不展示原文 和 译文
            if (!state.openEditSourceText || params.isCloseSource) {
              if (!el.sourcePlaceholder && !el.targetPlaceholder) {
                ArrMap.delete(el.sentId)
                return false
              } else {
                indexReduce++
              }
            }

            return true
          })


        commit('excelProject/changeKey', {
          docData: data,
          ArrMap: ArrMap,
        })

        //此处需要做改动，不要一开始就赋值。等新接口出来改造。
        nextTick(async () => {
          let isDiffCompare = state.excelProject.differentHighLightModel
          state.excelProject.docData.sentenceDTOList.forEach((item) => {
            // 判断比对值和上次数据的列表索引是否相同 相同则不取值;
            indexKeys.push(item.sentId)
            let compareHTML = isDiffCompare
              ? item.diffCompare
              : item.targetPlaceholder
            let node = document.getElementById(item.targetDomId)
            if (node.innerHTML != compareHTML) {
              //强行赋值
              node.innerHTML = compareHTML
            }
          })

          //如果差异对比是打开，需要清除原文上所有QAERROR
          if (isDiffCompare) {
            let sourceNode = document.getElementById(`new-edit-table-scroll`)
            let nodeList = sourceNode.getElementsByClassName('qaError')
            for (let i = 0; i < nodeList.length; i++) {
              nodeList[i].classList.remove('qaError')
              i--
            }
          }

          //isBackTop 筛选 分页 改变页码 搜索触发 回到顶部；同时重新赋值checkDetail
          if (params.isBackTop) {
            document.getElementsByClassName(
              'new-edit-table-scroll'
            )[0].scrollTop = 0
            let tableItem = state.excelProject.docData.sentenceDTOList[0] || {}
            state.excelProject.tabActiveIndex = 0
            state.checkedDetail = {
              ...tableItem,
            }
          }

          //检查是否是预览模式 非预览模式 才调用 避免产生不必要的数据库
          if (!getters.$isPreviewMode) {
            indexDbGetAllVal((dbResult) => {
              if (dbResult?.length) {
                // console.log(dbResult);
                //调用接口，将SentId传入进去；获取最新更新时间；然后删除里面的项，剩下的做自动存储；难道全部做sentCommit？ 还是做批量？；
                for (let i = 0; i < dbResult.length; i++) {
                  if (rootState.excelProject.ArrMap.has(dbResult[i].sentId)) {
                    //获取位置
                    let sentListItemIndex = rootState.excelProject.ArrMap.get(dbResult[i].sentId)
                    //如果存在进行下一步 无需调用接口
                    let sentListItem = rootState.excelProject.docData.sentenceDTOList[sentListItemIndex]
                    //如果没有更新时间，直接删除
                    if (!dbResult[i].updateTargetTime) {
                      deleteAutoCommit(sentListItem.sentId)
                    } else if (
                      dbResult[i].updateTargetTime < sentListItem.updateTargetTime
                    ) {
                      //更新时间小于后台更新时间删除此记录
                      deleteAutoCommit(sentListItem.sentId)
                    } else {
                      //添加到自动队列里面去 比较自动队列里面的updateTime？
                      addAutoCommitQueue(dbResult[i])
                      //同时赋值比较值//同时赋值页面；
                      sentListItem.compareTargetHTML = dbResult[i].targetPlaceholder
                      document.getElementById(sentListItem.targetDomId).innerHTML = dbResult[i].targetPlaceholder || ''
                    }
                  }
                }
              }
            })
          }

          //QA分段加载 如果需要提前定位，则等待
          if (!isDiffCompare && !state.openEditSourceText) {
            //如果未开启差异对比，原文编辑；则调用QA整页列表
            if (params.sentencePosition) {
              //如果有QA定位；则需要在第一页结束后返回promise
              dispatch('getPageQAHTMLResult', {
                sentList: indexKeys,
                sentencePosition: params.sentencePosition || '',
              }).then((res) => {
                //此时已经结束
                resolve(res)
                if (params.loading) {
                  state.screenLoading = false
                }
              })
            } else {
              //如果无QA定位；直接返回，QA画线能有光标
              dispatch('getPageQAHTMLResult', {
                sentList: indexKeys,
                sentencePosition: params.sentencePosition || '',
              })
              if (params.loading) {
                state.screenLoading = false
              }
            }
          } else {
            //如果开启差异对比/原文编辑；则不需要调用QA整页列表 直接返回即可
            resolve(res)
            if (params.loading) {
              state.screenLoading = false
            }
          }
        })
      } else {
        //如果接口出现异常则关闭loading
        if (params.loading) {
          state.screenLoading = false
        }
        reject('error')
      }
    })
  },

  // //获取机翻
  twinslatorMt({ commit, dispatch, state }: any, params: any) {
    return new Promise((resolve, reject) => {
      twinslatorMt(
        Object.assign(
          {
            docId: state.taskDetail.docId,
          },
          params
        )
      )
        .then((res: any) => {
          let result = res.data.result
          if (result == '0') {
            resolve(res)
            return
          } else if (result == '1') {
            // dispatch("getSentence");
            let list = res.data.data.list.map((item: any) => {
              //新
              let dom = window.$getTargetDom(item.sentId);
              if (dom) {
                let sentenceItm =
                  state.excelProject.docData.sentenceDTOList[
                    state.excelProject.ArrMap.get(item.sentId)
                  ]
                sentenceItm = Object.assign(sentenceItm, item)
                dispatch('updateTargetOrStatus', {
                  type: 'MT',
                  targetHTML: item.targetPlaceholder,
                  sentId: item.sentId,
                })
              }
              return { ...item }
            })
            dispatch('wordPreviewRenew', list)
            resolve(res)
          }
        })
        .catch((err: any) => {
          reject(err)
        })
        .finally(() => {
          // state.screenLoading = false;
        })
    })
  },
  //获取qa列表
  getAllSentenceLogs({ state, commit }: any, params: any) {
    //发送emit事件
    // if (store.state.rightToolbar.toolbarNav.tbNavActiveIndex == '4') {
    //
    // }
    mitt.emit('mittQA', 'search')
  },
  //获取qa进度
  async checkProgress({ state, commit, dispatch }: any) {
    const res = await checkProgress({})
    if (res.data.result == 1) {
      const data = res.data.data
      if (data.progress <= 0) {
        data.progress = 0
      } else if (data.progress == 100) {
        dispatch('getAllSentenceLogs')
      }

      commit('changeKey', {
        checkProgressDetail: data,
      })
      if (!data.running) {
        return
      }
    } else {
      return
    }
    setTimeout(() => {
      dispatch('checkProgress')
    }, 3000)
  },
}
