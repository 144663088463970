import store from '@/store/index.ts';
import useUtils from "@/plugins/utils";
const { setFocusEnd } = useUtils()
export const getFactTargetText = (item, key) => {
  let nodeStr = key ? item[key] : item.targetPlaceholder
  return nodeStr || item.target
}
// https://ali-file-cdn.iol8.com/webcat/img/placeholder/L${url}.png
export const getFactSourceText = (item) => {
  let nodeStr = item.sourcePlaceholder
  return nodeStr
}


//判断某一句是否在页面内；已经不需要了 开始学习three.js
export const isPageHasSentence=(sentId)=>{
  // if (store.state.excelProject.ArrMap.has(sentId)) {
  //
  // }
}

export const positionSentence = (sentId,isFocus) => {
  //需要判断是否处于折叠状态；根据sentId 查询当前列表数据，获取父级节点；打开折叠；
  let excelProject = store.state.excelProject;
  let index = excelProject.ArrMap.get(sentId);
  let tableItem = excelProject.docData.sentenceDTOList[index] || {};
  store.commit('changeKey', {
    checkedDetail: {
      ...tableItem,
    },
  })
  store.commit('excelProject/changeKey', {
    tabActiveIndex: index,
  })

  //如果列表处于分组状态；
  let timeout = 0;
  //如果处于被折叠状态；
  if (store.state.collapseGroup.includes(tableItem.groupParentId)) {
    //移除
    store.dispatch("removeCollapseGroup",{groupParentId:tableItem.groupParentId});
    //延迟半秒执行；因为有动画效果；不需要
    // timeout = 400;
  }

  setTimeout(()=>{
    let childNode = window.$getTargetDom(tableItem.sentId);
    let container = document.getElementsByClassName('new-edit-table-scroll')[0]

    container.scrollTop = 0

    const { left, top, height } = container.getBoundingClientRect()
    const { left: childLeft, top: childTop } = childNode.getBoundingClientRect()

    const diffY = Math.abs(top - childTop)
    if (diffY < height / 2) {
      container.scrollTop = 0
    } else {
      container.scrollTop += diffY - height / 2
    }
    if (isFocus){
      // console.log("聚焦")
      setFocusEnd(childNode,true)
    }
  },timeout)
}


export const isFocusPositionSource=()=>{
  let selection = window.getSelection();
  let focusNode = selection.focusNode;
  if (!focusNode) {
    return false
  }
  let node = focusNode;
  try {
    while (node && (node.nodeName == '#text' || node.className?.indexOf('source-sent-') < 0)) {
      if (node.parentNode) {
        node = node.parentNode
      } else {
        node = null
      }
    }
  } catch (rej) {
    return false;
  } finally {

  }
  if (node && node.className && node.className?.indexOf('source-sent-') > -1) {
    return node
  } else {
    return false
  }
}

export const isFocusPositionTarget=()=>{
  let selection = window.getSelection();
  let focusNode = selection.focusNode;
  if (!focusNode) {
    return false
  }
  let node = focusNode;
  try {
    while (node && (node.nodeName == '#text' || node.className?.indexOf('target-sent-') < 0)) {
      if (node.parentNode) {
        node = node.parentNode
      } else {
        node = null
      }
    }
  } catch (rej) {
    return false;
  }

  if (node && node.className && node.className?.indexOf('target-sent-') > -1) {
    return node
  } else {
    return false
  }
}

