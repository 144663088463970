import axiosRequest from '@/http/axios.js'
import qs from 'qs'
import { getQueryString } from '@/plugins/prototype'
import store from '../store/index'
import {message} from "ant-design-vue";
const sleepFunc = (time = 500) => {
  return new Promise((resolve: any) => {
    setTimeout(() => {
      resolve()
    }, time)
  })
}
const splitIndex = getQueryString('splitIndex')
const request = async (data) => {
  //增加headers默认值
  data.headers = data.headers || {
    'Content-Type': 'application/x-www-form-urlencoded',
  }
  //等待接口保持同步
  // if (data.waitCommit) {
  //   console.log(store.state.sentCommitPromise);
  //   // debugger
  // }
  //如果是需要等待的接口先延迟 （是因为sentCommit 做了防抖节流）
  if (data.waitCommit) {
    await sleepFunc(500)
    if (store.state.sentCommitPromise?.length) {
      await Promise.all(store.state.sentCommitPromise)
        .then((res) => {
          // console.log("promise.all",state.sentCommitPromise[0].PromiseState)
        })
        .catch((e) => {})
        .finally(async () => {
          store.commit('changeKey', {
            sentCommitPromise: [],
          })
        })
      await sleepFunc(300)
    }
  }
  return axiosRequest(data)
}

//获取语言列表
export function getLanguageList(data = {}) {
  return request({
    url: '/mt/info/getLanguageList',
    method: 'POST',
    data,
  })
}

// 获取句子翻译进度
export function getSentenceProgress(data = {}) {
  return request({
    url: '/sentence/task/progress',
    method: 'POST',
    data,
  })
}

//获取任务详情
export function getTaskDetail(data = {}) {
  return request({
    url: '/task/info/getTaskDetail',
    method: 'POST',
    data,
  })
}

//提交译文
export function sentCommit(data = {}) {
  return request({
    url: '/sentence/webcatSentence/commit',
    method: 'POST',
    data,
  })
}

//智能纠错
export function mistakeCheckFormat(data = {}) {
  return request({
    url: '/task/tools/mistakeCheckFormat',
    method: 'POST',
    data,
  })
}

//修改，新增
export function addPostilOrUpdate(data = {}) {
  return request({
    url: '/sentence/postil/store',
    method: 'POST',
    data,
  })
}

//获取备注的句子
export function getPostilList(data = {}) {
  return request({
    url: '/sentence/postil/queryPageList',
    method: 'POST',
    data,
  })
}

//获取句子的备注
export function getDetailList(data = {}) {
  return request({
    url: '/sentence/postil/getDetailList',
    method: 'POST',
    data,
  })
}

//获取全文句子备注
export function getAllDetailList(data = {}) {
  return request({
    url: '/sentence/postil/getTreePostilDetailResponse',
    method: 'POST',
    data,
  })
}

//删除批注
export function delPostil(data = {}) {
  return request({
    url: '/sentence/postil/delete',
    method: 'POST',
    data,
  })
}

//获取句子审校内容列表
export function getReviseDetailsList(data = {}) {
  return request({
    // url: "/pe7/revise/getReviseDetailsList",
    url: '/termbase/revise/getDetails',
    method: 'POST',
    data,
  })
}

//获取被审校过的句子
export function getReviseSentList(data = {}) {
  return request({
    // url: "/pe7/revise/getReviseSentList",
    url: '/termbase/revise/getSentPageList',
    method: 'POST',
    data,
  })
}

//批量删除词条
export function entryDeleteBatch(data = {}) {
  return request({
    // url: "/pe7/entry/entryDeleteBatch",
    url: '/termbase/entry/delete',
    method: 'POST',
    data,
  })
}

//查找替换
export function searchSent(data = {}) {
  return request({
    url: '/sentence/replace-translation/translationSearch',
    method: 'POST',
    data,
  })
}

//查找原文
export function allSearch(data = {}) {
  return request({
    url: '/sentence/webcatSentence/allSearch',
    method: 'POST',
    data,
  })
}

//全部替换
export function replaceTranslation(data = {}) {
  return request({
    url: '/sentence/replace-translation/commit',
    method: 'POST',
    data,
  })
}

//全局纠错-漏译检查
export function checkLeaved(data = {}) {
  return request({
    // url: "/pe7/doc/checkLeaved",
    url: '/sentence/webcatSentence/missTransCheck',
    method: 'POST',
    data,
  })
}

//全局纠错-拼写检查
export function mistakeCheckerAll(data = {}) {
  return request({
    // url: "/pe7/doc/mistakeCheckerAll",
    url: '/sentence/webcatSentence/spellCheckAll',
    method: 'POST',
    data,
  })
}

//添加术语
export function entryCreate(data = {}) {
  return request({
    // url: "/pe7/entry/entryCreate",
    url: '/termbase/entry/create',
    method: 'POST',
    data,
  })
}

//编辑术语
export function entryEdit(data = {}) {
  return request({
    // url: "/pe7/entry/entryEdit",
    url: '/termbase/entry/edit',
    method: 'POST',
    data,
  })
}

//获取库列表
export function getLibList(data = {}) {
  return request({
    // url: "/pe7/groupLib/getPrimaryLibList",
    url: '/termbase/library/getPrimaryLibList',
    method: 'POST',
    data,
  })
}

//获取翻译库
export function getTaskLibs(data = {}) {
  return request({
    // url: "/pe7/webcat/getTaskLibs",
    url: '/task/info/getTranslateSetting',
    method: 'POST',
    data,
  })
}

//v1.0.6 新增-获取翻译库
export function getTranslateSettingAll(data = {}) {
  return request({
    url: '/task/info/getTranslateSettingAll',
    method: 'POST',
    data,
  })
}

//提交导出译稿请求
export function startFileExport(data = {}) {
  return request({
    // url: "/pe7/file/startFileExport",
    url: '/doc/info/startDocExport',
    method: 'POST',
    data,
  })
}

//创建术语/语料库
export function createLib(data = {}) {
  return request({
    // url: "/pe7/lib/createLib",
    url: '/termbase/library/create',
    method: 'POST',
    data,
  })
}

//创建或修改个人任务
export function createOrUpdateTask(data = {}) {
  return request({
    url: '/pe7/task/createOrUpdateTask',
    method: 'POST',
    data,
  })
}

//项目-获取行业信息列表
export function getIndustryInfoList(data = {}) {
  return request({
    // url: "/pe7/group/getIndustryInfoList",
    url: '/termbase/library/getIndustryInfoList',
    method: 'POST',
    data,
  })
}

//任务-翻译库设置
export function updateTaskLibrary(data = {}) {
  return request({
    // url: "/pe7/webcat/updateTaskLibrary",
    url: '/task/info/saveTranslateSetting',
    method: 'POST',
    data,
  })
}

//清空译文
export function clearTranslation(data = {}) {
  return request({
    // url: "/pe7/webcat/clearTranslation",
    url: '/task/tools/clearTranslation',
    method: 'POST',
    data,
  })
}

//预处理-语料填充
export function corpusFill(data = {}) {
  return request({
    // url: "/pe7/webcat/corpusFill",
    url: '/termbase/match/corpusFill',
    method: 'POST',
    data,
  })
}

//预处理-机翻填充
export function mtFill(data = {}) {
  return request({
    // url: "/pe7/webcat/mtFill",
    url: '/mt/text/mtFill',
    method: 'POST',
    data,
  })
}

//预处理-获取机翻引擎
export function mtList(data = {}) {
  return request({
    // url: "/pe7/webcat/mtList",
    url: '/mt/info/queryRelatedMtList',
    method: 'POST',
    data,
  })
}

//table最终提交
export function setTaskStatus(data = {}) {
  return request({
    // url: "/pe7/task/setTaskStatus",
    url: '/task/info/finishTask',
    method: 'POST',
    data,
  })
}

//查词
export function getYouDaoTrans(data = {}) {
  return request({
    // url: "/pe7/doc/getYouDaoTrans",
    url: '/mt/text/getDicParaphrase',
    method: 'POST',
    data,
  })
}

//获取机翻

export function getMtResult(data = {}) {
  return request({
    // url: "/pe7/mt/textTranslation",
    url: '/mt/text/translate',
    method: 'POST',
    data,
  })
}

//获取机翻列表---webcat中未使用，被 /pe7/webcat/mtList 替代
export function getMtEngineList(data = {}) {
  return request({
    url: '/pe7/mt/getMtEngineList',
    method: 'POST',
    data,
  })
}

//上传-获取七牛上传token---webcat中未使用，上传文件的token
export function getNewUploadToken(data = {}) {
  return request({
    url: '/pe7/file/getNewUploadToken',
    method: 'POST',
    data,
  })
}

export function getTranslateWordCount(data = {}) {
  return request({
    // url: "/pe7/webcat/getTranslateWordCount",
    url: '/task/info/getFinishedWordCount',
    method: 'POST',
    data,
  })
}

/**
 * 稿件拆分-获取原文
 * @param {
 * fileId 稿件ID
 * }
 */
export function visibleHtml(data = {}) {
  return request({
    // url: "/pe7/group/visibleHtml",
    url: '/doc/parse/getDocSplitHtml',
    method: 'POST',
    data,
  })
}

/**
 * 稿件拆分-确定拆分
 * @param {
 * fileId 稿件ID
 * data 段落name
 * }
 */
export function visibleSplit(data = {}) {
  return request({
    // url: "/pe7/group/visibleSplit",
    url: '/doc/split/autoSplit',
    method: 'POST',
    data,
  })
}

/**
 * 稿件拆分-稿件拆分确认
 * @param {
 * fileId 稿件ID
 * data 段落name
 * }
 */
export function newFileSplit(data = {}) {
  return request({
    // url: "/pe7/group/newFileSplit",
    url: '/doc/split/getDocSplitResult',
    method: 'POST',
    data,
  })
}

/**
 * 稿件预览模式
 * @param {
 * }
 */
export function getPreviewDetail(data = {}) {
  return request({
    url: '/task/info/getPreviewDetail',
    method: 'POST',
    data,
  })
}

/**
 * 获取初始化按钮、工具栏
 * @param {
 * }
 */
export function getOptionResource(data = {}) {
  return request({
    url: '/task/info/getOptionResource',
    method: 'POST',
    data,
  })
}

//预览模式下 初始化按钮，工具栏
export function getPreviewModelOptionResource(data = {}) {
  return request({
    url: '/task/info/getPreviewModelOptionResource',
    method: 'POST',
    data,
  })
}

/**
 * 暂停开始
 * @param {
 * }
 */
export function increaseDuration(data = {}) {
  return request({
    url: '/task/info/increaseDuration',
    method: 'POST',
    data,
  })
}

/**
 * 7.2.18 成绩报告
 * @param {
 * }
 */
export function getTranslateReport(data = {}) {
  return request({
    url: '/task/tools/getTranslateReport',
    method: 'POST',
    data,
  })
}

/**
 * 【7.2.18】精准搜索相关库词条
 * @param {
 * }
 */
export function libEntrySearch(data = {}) {
  return request({
    url: '/termbase/library/libEntrySearchNew',
    method: 'POST',
    data,
  })
}

/**
 * 【7.3.0】获取任务文档术语提取内容列表
 * @param {
 * }
 */
export function getTermExtractList(data = {}) {
  return request({
    url: '/task/termExtract/list',
    method: 'POST',
    data,
  })
}

/**
 * 【7.3.0】标记保存术语提取
 * @param {
 * }
 */
export function contentSave(data = {}) {
  return request({
    url: '/task/termExtract/contentSave',
    method: 'POST',
    data,
  })
}

/**
 * 【7.3.0】标记保存术语提取
 * @param {
 * }
 */
export function startUse(data = {}) {
  return request({
    url: '/task/termExtract/confirm',
    method: 'POST',
    data,
  })
}

/**
 * 【7.3.0】 获取任务文档术语提取引擎列表
 * @param {
 * }
 */
export function twinslatorList(data = {}) {
  return request({
    url: '/task/termExtract/twinslatorList',
    method: 'POST',
    data,
  })
}

/**
 * 【7.3.0】 术语提取：不了，谢谢
 * @param {
 * }
 */
export function thanks(data = {}) {
  return request({
    url: '/task/termExtract/thanks',
    method: 'POST',
    data,
  })
}

/**
 * 【7.3.2】 日志
 * @param {
 * }
 */
export function fetchTranslationLogs(data = {}) {
  return request({
    url: '/sentence/webcatSentence/fetchTranslationLogs',
    method: 'POST',
    data,
  })
}

/**
 * 【1.0.8】 将改动同步到Twinslator
 * @param {
 * }
 */
export function syncReportToTwinslator(data = {}) {
  return request({
    url: '/task/tools/syncReportToTwinslator',
    method: 'POST',
    data,
  })
}

/**
 * 【1.1.5】 word-项目任务-获取当页sentID
 * @param {
 * }
 */
export function getSentence(data = {}) {
  return request({
    url: '/sentence/webcatSentence/getSentence',
    method: 'POST',
    data,
    waitCommit: true,
  })
}

/**
 * 【1.1.5】 项目任务-单句确认取消
 * @param {
 * }
 */
export function confirmSentences(data = {}) {
  return request({
    url: '/sentence/webcatSentence/confirmSentences',
    method: 'POST',
    data,
  })
}

/**
 * 【1.1.5】 项目任务-单句确认取消
 * @param {
 * }
 */
export function addTwinslatorTerm(data = {}) {
  return request({
    url: '/termbase/entry/addTwinslatorTerm',
    method: 'POST',
    data,
  })
}

/**
 * 【1.1.5】 项目任务-成效
 * @param {
 * }
 */
export function twinslatorMatch(data = {}) {
  return request({
    url: '/termbase/match/twinslatorAndGroupMatch',
    method: 'POST',
    data,
  })
}

/**
 * 【1.1.5】 项目任务-成效
 * @param {
 * }
 */
export function replaceTwinslatorTerm(data = {}) {
  return request({
    url: '/termbase/entry/replaceTwinslatorTerm',
    method: 'POST',
    data,
  })
}

/**
 * 【1.1.5】 项目任务-多句机翻
 */

export function twinslatorMt(data = {}) {
  return request({
    url: '/mt/text/twinslatorMt',
    method: 'POST',
    data,
    waitCommit: true,
  })
}

/**
 * 【1.1.5】 项目任务-新增语料,修改建议
 * @param {
 * }
 */
export function addTwinslatorCorpus(data = {}) {
  return request({
    url: '/termbase/entry/addTwinslatorCorpus',
    method: 'POST',
    data,
  })
}

/**
 * 【1.1.5】 项目任务-应用并替换语料
 * @param {
 * }
 */
export function replaceTwinslatorCorpus(data = {}) {
  return request({
    url: '/termbase/entry/replaceTwinslatorCorpus',
    method: 'POST',
    data,
  })
}

/**
 * 【8.0.0】 预览-文档预览句子信息
 * @param {
 * }
 */
export function getSentencePreview(data = {}) {
  return request({
    url: '/sentence/webcatSentence/sentence/preview',
    method: 'POST',
    data,
  })
}

export function newMtList(data: {}) {
  return request({
    url: '/mt/info/queryTwinslatorMtList',
    method: 'POST',
    data,
  })
}

export function getTwinslators(data: {}) {
  return request({
    url: '/mt/info/getAllTwinslators',
    method: 'POST',
    data,
  })
}

export function getTranslateSetting(data: {}) {
  return request({
    url: '/task/info/getTwinslatorTranslateSettingAll',
    method: 'POST',
    data,
  })
}

export function clearTranslationTarget(data: {}) {
  return request({
    url: '/sentence/webcatSentence/clear-translation-target',
    method: 'POST',
    data,
    waitCommit: true,
  })
}

//QA预览列表
export function allSentenceLogs(data: {}) {
  let newData = Object.assign({ splitIndex: splitIndex }, data)
  return request({
    url: '/sentence/sentence-check/all-sentence-logs',
    method: 'POST',
    data: newData,
  })
}

//全文检查
export function sentenceCheckAll(data: {}) {
  return request({
    url: '/sentence/sentence-check/all',
    method: 'POST',
    data,
  })
}

//忽略或恢复单句QA
export function ignoreQAErrorApi(data: {}) {
  return request({
    url: '/sentence/sentence-check/ignore-sentence',
    method: 'POST',
    data,
  })
}

//sentId查QA
export function getListQATypesApi(data: {}) {
  let newData = Object.assign(
    { splitIndex: splitIndex, docId: store.state.taskDetail.docId },
    data
  )
  return request({
    url: '/sentence/sentence-check/list-qa-types',
    method: 'GET',
    data: newData,
  })
}

//相同句子QAlist
export function getSameQASentListApi(data: {}) {
  return request({
    url: '/sentence/sentence-check/list-same-sent-qa',
    method: 'GET',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

//全文忽略
export function ignoreDocAll(data: {}) {
  return request({
    url: '/sentence/sentence-check/ignore-doc',
    method: 'POST',
    data,
  })
}

//拼写无误
export function spellJoinDicApi(data: {}) {
  return request({
    url: '/sentence/sentence-check/join-dic',
    method: 'POST',
    data,
  })
}

//qa计算全文检查进度
export function checkProgress(data: {}) {
  return request({
    url: '/sentence/sentence-check/progress',
    method: 'POST',
    data,
  })
}

//qa关闭检查任务
export function closeProgress(data: {}) {
  return request({
    url: '/sentence/sentence-check/close-progress',
    method: 'POST',
    data,
  })
}
// 译文预览接口
export function translationPreview(data: {}) {
  return request({
    url: '/doc/info/translation-preview',
    method: 'POST',
    data,
  })
}

//译文预览状态接口
export function translationInfo(data: {}) {
  return request({
    url: '/doc/info/translation-preview/translation-info',
    method: 'POST',
    data,
  })
}

export function getTwinslatorTranslateSettingAll(data: {}) {
  return request({
    url: '/task/info/getTwinslatorTranslateSettingAll',
    method: 'POST',
    data,
  })
}

//获取单句QA错误的提示
export function getQaSentenceLogApi(data: {}) {
  let newData = Object.assign(
    { splitIndex: splitIndex, docId: store.state.taskDetail.docId },
    data
  )
  return request({
    url: '/sentence/sentence-check/sentence-log',
    method: 'POST',
    data: newData,
  })
}

//获取机翻收费
export function getTwinslatorMtPrice(data: {}) {
  return request({
    url: '/mt/text/twinslatorMtPrice',
    method: 'POST',
    data,
  })
}

//提交任务提示
export function getSubmitTaskPrompt(data: {}) {
  return request({
    url: '/sentence/webcatSentence/submit-task-prompt',
    method: 'POST',
    data,
  })
}

//进度刷新接口
export function getDocExportCacheList(data: {}) {
  return request({
    url: '/doc/info/getDocExportCacheList',
    method: 'POST',
    data,
  })
}

//获取项目信息
export function getProjectDetail(data: {}) {
  return request({
    url: '/task/group/getProjectById',
    method: 'get',
    data,
  })
}

//收藏引擎
export function favoriteTw(data: {}) {
  return request({
    url: '/mt/info/favorite',
    method: 'post',
    data,
  })
}

//引擎是否评分
export function existsScoreByDocId(data: {}) {
  return request({
    url: '/mt/doc/existsScoreByDocId',
    method: 'post',
    data,
  })
}
///mt/doc/addScore 引擎打分
export function addScore(data: {}) {
  return request({
    url: '/mt/doc/addScore',
    method: 'post',
    data,
  })
}

//查询文字术语匹配
export function termMatch(data: {}) {
  return request({
    url: '/termbase/match/termMatch',
    method: 'post',
    data,
  })
}

//查询评价对象
export function getScoreObj(data: {}) {
  return request({
    url: '/task/webcat/translator-score-prompt',
    method: 'post',
    data,
  })
}

//评价议员
export function setScoreObj(data: {}) {
  return request({
    url: '/task/webcat/translator-score',
    method: 'post',
    data,
  })
}

// 获取上一个议员的信息
export function getTranslatorInfo(data: {}) {
  return request({
    url: '/task/webcat/previous-task-translator-info',
    method: 'post',
    data,
  })
}


//编辑原文提交接口
export function updateSourceEdit(data: {}) {
  return request({
    url: '/sentence/webcatSentence/update-source',
    method: 'post',
    data,
  })
}

//大审校导出
export function exportAdminManager(data: {}) {
  return request({
    url: '/sentence/webcatSentence/sentence/export-admin-manager',
    method: 'post',
    data,
  })
}
//大审校导出
export function foundAdminManager(data) {
  return request({
    url: `/sentence/webcatSentence/sentence/export-admin-manager-detail`,
    method: 'get',
    data: data,
  })
}

//大审校导出
export function getRecommendTwinslators(data) {
  return request({
    url: `/mt/doc/getRecommendTwinslators`,
    method: 'post',
    data,
  })
}

//审核通过
export function infoAuditTask(data) {
  return request({
    url: `/task/info/auditTask`,
    method: 'post',
    data,
  })
}

export function getRechargeList(data) {
  return request({
    url: `/v1/mt/recharge/list`,
    method: 'get',
    data,
  })
}

export function createRecharge(data) {
  return request({
    url: `/v1/mt/recharge/create`,
    method: 'post',
    data,
  })
}

export function rechargeResult(data) {
  return request({
    url: `/v1/mt/recharge/get`,
    method: 'get',
    data,
  })
}

//原文编辑模式批量改动
export function updateSourceTarget(data) {
  return request({
    url: `/sentence/webcatSentence/update-source-target`,
    method: 'post',
    data,
  })
}

//译文编辑批量改动
export function updateBatchTarget(data) {
  return request({
    url: `/sentence/webcatSentence/batch-save-target`,
    method: 'post',
    data,
  })
}

//获取术语语料平台的Ticket
export function getTermCorpusTicket(data) {
  return request({
    url: `/termbase/term-corpus/ticket`,
    method: 'post',
    data,
  })
}

//智能推荐
export function getAiSmartResult(data) {
  return request({
    url: `/termbase/twins/smart/recommend`,
    method: 'post',
    data,
  })
}

//术语重译
export function postTermRetranslate(data) {
  return request({
    url: `/termbase/sentence/termRetranslate`,
    method: 'post',
    data,
  })
}

//获取术语重译信息
export function postTermRetranslateRunning(data = {}) {
  return request({
    url: `/termbase/sentence/getRunningBlockingTask`,
    method: 'post',
    data,
  })
}

//添加推荐内容应用记录
export function postTranslationAdd(data = {}) {
  return request({
    // headers:{
    //   contentType:"application/json",
    // },
    url: `/sentence/translation/apply/log/add`,
    method: 'post',
    data,
  })
}

//润色
export function getAiDecorateResult(data) {
  return request({
    headers: {
      'Content-Type': 'application/json',
    },
    url: `/termbase/twins/ai/decorate`,
    method: 'post',
    data,
  })
}

//获取推荐历史
export function getSmartHistory(data) {
  return request({
    // headers:{
    //   contentType:"application/json",
    // },
    url: `/sentence/translation/apply/log/history`,
    method: 'get',
    data,
  })
}

//充值相关
export function getPackCoupon(data) {
  return request({
    // headers:{
    //   contentType:"application/json",
    // },
    url: `/v1/mt/recharge/pack-coupon`,
    method: 'get',
    data,
  })
}

export function checkReplaceProgress(data = {}) {
  return request({
    url: `/sentence/replace-translation/progress`,
    method: 'get',
    data: data,
  })
}

export function getPreviewInfo(data = {}) {
  return request({
    // headers:{
    //   contentType:"application/json",
    // },
    url: `/doc/parse/html/getPreviewInfo`,
    method: 'get',
    data: data,
  })
}

export function getSentTarget(data = {}) {
  return request({
    // headers:{
    //   contentType:"application/json",
    // },
    url: `/sentence/webcatSentence/get-sent-target`,
    method: 'post',
    data: data,
  })
}

//删除术语
export function postDeleteTerm(data = {}) {
  return request({
    // headers:{
    //   contentType:"application/json",
    // },
    url: `/termbase/entry/delete`,
    method: 'post',
    data: data,
  })
}

export function getQAHtml(data) {
  let newData = Object.assign(
    { splitIndex: splitIndex, docId: store.state.taskDetail.docId },
    data
  )
  return request({
    // headers:{
    //   contentType:"application/json",
    // },
    url: `/sentence/webcatSentence/get-placeholder-with-qa`,
    method: 'post',
    data: newData,
  })
}

export function referenceTerm(data) {
  return request({
    // headers:{
    //   contentType:"application/json",
    // },
    url: `/termbase/entry/reference`,
    method: 'post',
    data: data,
  })
}

//调用质检接口
export function getQualityCheckResult(data) {
  return request({
    url: `/termbase/twins/ai/quality/check`,
    method: 'get',
    data: data,
  })
}

//批量质检接口
export function batchQualityCheck(data) {
  return request({
    url: '/termbase/twins/ai/batch/quality/check',
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

// 轮询质检结果
export function qualityCheckResult(data) {
  return request({
    url: '/termbase/twins/get/batch/quality/check/result',
    method: 'get',
    data: data,
  })
}

// 查询是否Gdp审校
export function getShowGptCheck(data) {
  return request({
    url: '/termbase/twins/show/gpt/check',
    method: 'get',
    data: data,
  })
}

// 查询是否Gdp审校
export function getTwinsLimit(data) {
  return request({
    url: '/termbase/twins/limit/statistics',
    method: 'get',
    data: data,
  })
}

//获取润色数据
export function getDecorateList(data) {
  return request({
    url: '/termbase/twins/list/decorate',
    method: 'get',
    data: data,
  })
}

//展示校验是否展示孪生译员按钮
export function getShowTwinsButton(data) {
  return request({
    url: '/termbase/twins/check/show/button',
    method: 'get',
    data: data,
  })
}

//webcat计费
export function postTwinsCalculate(data) {
  return request({
    url: '/termbase/cost/pre/calculate',
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

//查询当前句子质检信息
export function searchQualityResult(data) {
  return request({
    url: '/termbase/twins/ai/quality/result',
    method: 'get',
    data: data,
  })
}

//获取术语重译匹配到的句子
export function searchCheckSentences(data) {
  return request({
    url: '/termbase/sentence/check/sentences',
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

//任务用户权益
export function getUserLevelPrivilege(data) {
  return request({
    url: '/task/info/getUserLevelPrivilege',
    method: 'post',
    data: data,
  })
}

//获取句子孪生译员对话记录
export function getAiListLogs(data) {
  return request({
    url: '/sentence/sentence-dialogue/list/logs',
    method: 'get',
    data: data,
  })
}

// /termbase/twins/work/state/set

// 设置孪生译员
export function postTwinsSetStatus(data) {
  return request({
    url: '/termbase/twins/edit/setting',
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}
//获取孪生译员状态
export function getTwinsSetStatus(data) {
  return request({
    url: '/termbase/twins/get/setting',
    method: 'get',
    data: data,
  })
}

//批量润色
export function batchAiDecorate(data) {
  return request({
    url: '/termbase/twins/batch/ai/decorate',
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

//重算
export function postReComputePage(data): any {
  //接口增加改造，如果处于分组条件，则提示处于分组之中，不要做处理；
  //如果分组打开；则返回 new promise.reject()
  if (store.state.excelProject.groupBy) {
    return new Promise((resolve,reject)=>{
      message.warning("当前分组，无法定位到该句段，可取消分组重试")
      reject(false)
    })
  } else {
    data.splitIndex = getQueryString('splitIndex')
    return request({
      url: '/sentence/webcatSentence/reComputePage',
      method: 'post',
      data: data,
    })
  }
}

//查询Ai问话类型
export function getDialogueNotify(data) {
  return request({
    url: '/termbase/twins/dialogue/notify',
    method: 'post',
    data: data,
  })
}

//忽略术语
export function getIgnoreTerm(data) {
  return request({
    url: '/sentence/sentence-dialogue/term/ignore',
    method: 'get',
    data: data,
  })
}

//添加术语
export function postAddTermList(data) {
  return request({
    url: '/termbase/twins/apply/terms',
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}
//获取术语接口
export function getTermList(data) {
  return request({
    url: '/sentence/sentence-dialogue/list/terms',
    method: 'get',
    data: data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

// 指令对话
export function postInstructionTalk(data) {
  return request({
    url: '/termbase/twins/user/instruction',
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

// https://ir-mock.transn.net/project/561/interface/api/19655

//获取单句QA树结构

// /sentence/webcatSentence/get-last-modified-sent

export function getLastModifiedSent(data) {
  return request({
    url: '/sentence/webcatSentence/get-last-modified-sent',
    method: 'post',
    data: data,
  })
}
// export function getTreePostilDetailResponse(){
//
// }

//获取译力余额
export function getRestPower(data) {
  return request({
    url: '/termbase/cost/rest/power',
    method: 'get',
    data: data,
  })
}

//获取当前审校流程
export function getTaskConfirmProcess(data) {
  return request({
    url: '/termbase/cost/rest/power',
    method: 'get',
    data: data,
  })
}

// 解锁句子

export function webcatSentenceUnLock(data) {
  return request({
    url: '/sentence/webcatSentence/lock-or-unlock-sent',
    method: 'post',
    data: data,
    waitCommit: true,
  })
}

//拆分句子
export function splitWebcatSentence(data) {
  return request({
    url: '/sentence/webcatSentence/split',
    method: 'post',
    data: data,
    waitCommit: true,
  })
}

//合并句子 v1
export function mergeWebcatSentence(data) {
  return request({
    url: '/sentence/webcatSentence/v1/merge',
    method: 'post',
    data: data,
    waitCommit: true,
  })
}
//合并句子 v2
export function mergeWebcatSentenceV2Api(data) {
  return request({
    url: '/sentence/webcatSentence/v2/merge',
    method: 'post',
    data: data,
    waitCommit: true,
  })
}

//合并预览
export function postPreviewMergeResultApi(data) {
  return request({
    url: '/sentence/webcatSentence/preview-merge-result',
    method: 'post',
    data: data,
    waitCommit: true,
  })
}

//取消合并预览
export function postCancelPreviewMergeApi(data){
  return request({
    url:  '/sentence/webcatSentence/preview-cancel-merge-result',
    method: 'post',
    data: data,
    waitCommit: true,
  })
}

//取消合并
export function postCancelMergeApi(data){
  return request({
    url:  '/sentence/webcatSentence/cancel-merge',
    method: 'post',
    data: data,
    waitCommit: true,
  })
}

//获取余额
export function getBalance(data = {}) {
  return request({
    url: '/termbase/cost/getBalance',
    method: 'post',
    data: data,
  })
}

//获取定制版
export function getDomainDetail(data = {}) {
  return request({
    url: '/v1/enterprise/settings',
    method: 'get',
    data,
  })
}

//设置QA全文检查选项
export function setQASettingApi(data = {}) {
  return request({
    url: '/task/info/saveUserQASetting',
    method: 'post',
    data: data,
  })
}

//获取QA设置选项
export function getQASettingApi(data = {}) {
  return request({
    url: '/task/info/getUserQASetting',
    method: 'post',
    data: data,
  })
}

//停止批量替换等操作
export function postStopReplaceApi(data = {}) {
  return request({
    url: '/sentence/replace-translation/interrupt',
    method: 'post',
    data: data,
  })
}

//停止批量润色等操作
export function postStopTwinsApi(data = {}) {
  return request({
    url: '/termbase/twins/stop/blocking-task',
    method: 'get',
    data: data,
  })
}

//获取服务器时间戳
export function getServerTimes(data = {}) {
  return request({
    url: '/task/webcat/getServerTimes',
    method: 'get',
    data: data,
  })
}

//获取聊天组信息；
export function getIMGroupInfoApi(data = {}) {
  return request({
    url: '/task/tools/getImGroupInfo',
    method: 'post',
    data: data,
  })
}

//获取批量翻译文档列表
export function getBatchDocList(data = {}) {
  return request({
    url: '/doc/info/batchGetDocList',
    method: 'post',
    data: data,
  })
}

// /mt/info/checkUserCreateTwinslator

export function getCheckUserCreateTwinslator(data = {}) {
  return request({
    url: '/mt/info/checkUserCreateTwinslator',
    method: 'post',
    data: data,
  })
}

//取消同步状态
export function postCancelSync(data = {}) {
  return request({
    url: '/sentence/webcatSentence/cancel-sync',
    method: 'post',
    data: data,
  })
}

//获取句子同步信息

export function postSyncInfo(data = {}) {
  return request({
    url: '/sentence/webcatSentence/get-sync-info',
    method: 'get',
    data: data,
  })
}

//句子打开同步状态
export function postOpenSyncStatus(data = {}) {
  return request({
    url: '/sentence/webcatSentence/sync',
    method: 'post',
    data: data,
  })
}

//添加术语前检查冲突
export function postCheckAndGetConflict(data = {}) {
  return request({
    url: '/termbase/entry/checkAndGetConflict',
    method: 'post',
    data: data,
  })
}

// 检查勾选句子是否存在快照
export function postCheckExistCount(data = {}) {
  return request({
    url: '/sentence/snapshot/check_exist_count',
    method: 'post',
    data: data,
  })
}

// 恢复快照
export function postRecoverSnapshot(data = {}) {
  return request({
    url: '/sentence/snapshot/recover',
    method: 'post',
    data: data,
  })
}

//获取正则示例
export function postListRegexExampleApi(data = {}) {
  return request({
    url: '/sentence/webcatSentence/list_regex_example',
    method: 'get',
    data: data,
  })
}

//查询此任务空译文数量
export function postGetNoTranslateSentCountApi(data = {}) {
  return request({
    url: '/doc/info/getNoTranslateSentCount',
    method: 'post',
    data: data,
  })
}

//术语匹配单独接口
export function postTwinslatorTermMatch(data={}) {
  return request({
    url: '/termbase/match/twinslatorTermMatch',
    method: 'post',
    data: data,
  })
}

//语料匹配单独接口
export function postTwinslatorCorpusMatch(data={}) {
  return request({
    url: '/termbase/match/twinslatorCorpusMatch',
    method: 'post',
    data: data,
  })
}
