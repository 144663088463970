<template>
  <div class="projectWord-tables">
    <splitpanes
        class="default-theme"
        horizontal
        style="height: 100%;"
        @resize="onPaneResize"
        :dbl-click-splitter="false"
        v-if="mounted"
    >
      <pane :size="100">
        <div class="bottomContainer-Room" :class="{ fullScreen: fullScreen }">
          <bottomContainer
              ref="bottomContainerRef"
              :scrollHeight="previewScrollHeight"
              :controlOpen="paneSize"
              :minSize="minSize"
              :fromPage="'Preview'"
          ></bottomContainer>
        </div>
      </pane>
    </splitpanes>
  </div>
</template>

<script>
import {
  computed,
  ref,
  nextTick,
  onBeforeMount,
  reactive,
  toRefs,
  onMounted,
} from 'vue'
import { useStore } from 'vuex'
import bottomContainer from '@/components/source/module/wordExcel/bottomContainer.vue'
import mitt from '@/plugins/bus'
import { Splitpanes, Pane } from 'splitpanes'
import {getUserLevelPrivilege} from "@/http/api";
import {useRoute} from "vue-router";

export default {
  components: {
    bottomContainer,
    Splitpanes,
    Pane,
  },
  setup() {
    const store = useStore()
    const route = useRoute();
    // const { getPagesSentId } = useUtils();
    // const pageSourceObject = computed(() => store.state.pageSourceObject);
    //除了标题以及上方工具栏以外剩余的高度
    const bottomContainerRef = ref(null)

    const fullScreen = computed(() => store.state.fullScreen)
    const tableScrollRef = ref(null)

    const state = reactive({
      paneSize:100,
      previewScrollHeight: 0,
      timer: null,
      minSize: 4,
      mounted: false,
    })

    //打开下方

    //关闭下方
    const onPaneResize = (e) => {
      // console.log(e)
    }


    const init = async ()=>{
      let result = await getUserLevelPrivilege({})
      // let sentId = ro

      store.commit('changeKey', {
        userLevelPrivilege: result.data.data,
      })
      store.dispatch("getTaskDetail").then(res=>{
        if (route.query.sentId) {
          store.commit("changeKey",{
            checkedDetail:{
              sentId:route.query.sentId,
              docId:route.query.docId,
              sentIndex:route.query.sentIndex,
            }
          })
        }
        state.mounted = true
        setTimeout(() => {
          state.previewScrollHeight = document.getElementsByClassName(`bottomContainer-Room`)[0].offsetHeight - 42;
          bottomContainerRef.value.updatePreviewRefScroll()
        }, 1000)
      })
    }

    onMounted(() => {
      init();

      window.onresize = function() {
        console.log("尺寸变化")
        if (state.timer) {
          clearTimeout(state.timer)
        }
        state.timer = setTimeout(() => {
          state.previewScrollHeight = document.getElementsByClassName(`bottomContainer-Room`)[0].offsetHeight - 42;
          bottomContainerRef.value.updatePreviewRefScroll()
        }, 500)
      }
    })

    return {
      init,
      tableScrollRef,
      onPaneResize,
      bottomContainerRef,
      fullScreen,
      ...toRefs(state),
      store,
      checkProgressDetail: computed(() => store.state.checkProgressDetail),
    }
  },
}
</script>

<style lang="less" scoped>
.projectWord-tables {
  height: 100vh;
  //background: red;
  overflow: hidden;
  .topContainer-Room {
    background: var(--universal-color-table);
    height: 100%;
    overflow: hidden;
  }
  .bottomContainer-Room {
    height: 100%;
    box-shadow: 0px -2px 2px 0px rgba(2, 73, 89, 0.05);
    width: 100%;
    overflow: hidden;
    &.fullScreen {
      top: 0;
      z-index: 6;
      position: fixed;
      height: 100% !important;
      width: 100%;
      background: #f5f9fa;
    }
  }
}
</style>
