<template>
  <div class="termCorpus">
    <div class="termCorpus-input-search">
      <a-input-search
        @focus="onSearch"
        placeHolder="搜索全部术语/语料或网络释义"
      ></a-input-search>
    </div>
    <div class="ai-result-tips" :class="{ topping: state.topping == 2 }">
      <!-- 术语提示 -->
      <div class="term-result">
        <div class="title">
          <span class="left">术语提示</span>
          <span
            class="right font-size-12"
            @click="toppingChange(1)"
            v-show="state.topping == 2"
          >
            <icon-font type="icon-zhiding" class="icon margin-r-4"></icon-font>
            置顶
          </span>
        </div>
        <div
          class="term-result-content"
          v-if="twinslatorMatch.matchedTerm?.length"
        >
          <div
            class="term-result-item"
            v-for="(item, index) in twinslatorMatch.matchedTerm"
            :key="index"
          >
            <a-tooltip v-if="!item.replaced">
              <template #title>
                术语漏译
              </template>
              <icon-font
                type="icon-information-fill"
                class="font-size-16 margin-r-4"
                style="color:#f55c3f;position: relative;top: 1px"
              ></icon-font>
            </a-tooltip>
            <a-tooltip>
              <template #title>
                <button
                  @mousedown="applyTerm(item, 'insert')"
                  class="design-apply-text-button"
                >
                  点击插入光标处
                </button>
              </template>
              <button
                class="design-apply-text-button text-left"
                @mousedown="applyTerm(item, 'insert')"
                style="word-break: break-all"
              >
                <span class="source source-language-text-style" style="display: inline-block">
                  {{ item.source }}
                </span>
                <span class="target target-language-text-style" style="display: inline-block">
                  {{ item.target }}
                </span>
                <span class="comment" v-if="item.comment">
                  注：{{ item.comment }}
                </span>
              </button>
            </a-tooltip>
            <a-tooltip overlayClassName="white-tooltip">
              <template #title>
                <div class="more-tooltip">
                  <div class="more-tooltip-header">
                    <a-button
                      size="small"
                      shape="round"
                      class="margin-r-8"
                      @click="editTerm(item)"
                    >
                      修改
                    </a-button>
                    <a-button
                      size="small"
                      shape="round"
                      class="margin-r-8"
                      @click="retranslation(item)"
                    >
                      重译
                    </a-button>
                    <a-button size="small" shape="round" @click="delTerm(item)">
                      删除
                    </a-button>
                  </div>
                  <div class="more-tooltip-content">
                    <div>
                      状态：{{ item.isAudit == 1 ? '已审核' : '未审核' }}
                    </div>
                    <div>来源：《{{ item.libName }}》</div>
                    <div>更新：{{ item.createUserName }}</div>
                    <div>
                      时间：{{
                        dateFormat(item.lastModifyTime, 'YYYY-mm-dd HH:MM')
                      }}
                    </div>
                  </div>
                </div>
              </template>
              <span class="more">
                <icon-font type="icon-dian"></icon-font>
              </span>
            </a-tooltip>
          </div>
        </div>
        <div v-else class="font-size-13 margin-b-8" style="color:#C9CDD4">
          暂无相关术语
        </div>
      </div>
      <div class="corpus-result">
        <div class="title">
          <span class="left">语料提示</span>
          <span
            class="right font-size-12"
            @click="toppingChange(2)"
            v-show="state.topping == 1"
          >
            <icon-font type="icon-zhiding" class="icon margin-r-4"></icon-font>
            置顶
          </span>
        </div>
        <div
          class="corpus-result-content"
          v-if="twinslatorMatch.matchedCorpus?.length"
        >
          <div
            class="corpus-result-item"
            v-for="(item, index) in twinslatorMatch.matchedCorpus"
            :key="index"
          >
            <div class="source sourceDiff">
              <span class="matchRate">{{ item.matchRate }}%</span>
              <span class="source-language-text-style" v-html="item.sourceDiffcompare || item.source" :style="{display:isSpecialLanguageText?'inline-block':''}"></span>
            </div>
            <div class="target target-language-text-style" v-html="item.target"></div>
            <div class="comment" v-if="item.comment">
             {{ item.comment }}
            </div>
            <div class="apply">
              <div>
                <NButton
                    size="small"
                    shape="round"
                    :disabled="checkedDetail.locked || disabled"
                    @click="applyCorpus(item, 'corpus')"
                >
                  应用
                </NButton>
                <a-tooltip v-if="item.matchRate == 100">
                  <template #title>
                    100%语料没填充可能的原因：<br>
                    1、未做译前处理，或译前处理未勾选语料填充；<br>
                    2、100%语料是译前处理后新增或导入的；<br>
                    3、重复率分析选择带样式模式，文本相同样式不同产生了100%语料，但不为重复句，未自动填充。
                  </template>
                  <QuestionCircleOutlined style="color:#ddd;font-size: 13px;margin-left: 4px;"/>
                </a-tooltip>
              </div>
              <a-tooltip overlayClassName="white-tooltip">
                <template #title>
                  <div class="more-tooltip">
                    <div class="more-tooltip-content">
                      <div v-if="!item.isGroupCorpus">
                        状态：
                        <span v-if="item.isAudit == 1">已审核</span>
                        <span
                          v-else-if="item.isAudit == 2 && !item.isGroupCorpus"
                        >
                          未审核
                        </span>
                      </div>
                      <div>来源：《{{ item.libName }}》</div>
                      <!--                      <div>-->
                      <!--                        时间：{{-->
                      <!--                          dateFormat(item.lastModifyTime, "YYYY-mm-dd HH:MM")-->
                      <!--                        }}-->
                      <!--                      </div>-->
                    </div>
                  </div>
                </template>
                <div class="info">
                  <span
                    class="font-size-12 margin-l-12 createUserName"
                    style="color:#C9CDD4"
                  >
                    {{ item.createUserName }}
                  </span>
                  <span class="font-size-12 margin-l-12" style="color:#C9CDD4">
                    {{ dateFormat(item.lastModifyTime, 'YYYY-mm-dd HH:MM') }}
                  </span>
                  <icon-font type="icon-dian" class="icon"></icon-font>
                </div>
              </a-tooltip>
            </div>
          </div>
        </div>
        <div v-else class="font-size-13 margin-b-8" style="color:#C9CDD4">
          暂无相关语料
        </div>
      </div>
    </div>
  </div>
  <edit-term-modal
    v-if="state.editTermVisible || state.delVisible"
    v-model:visible="state.editTermVisible"
    v-model:termDetail="state.termDetail"
    v-model:delVisible="state.delVisible"
  ></edit-term-modal>
</template>

<script setup>
  import { computed, nextTick, reactive, onBeforeMount } from 'vue'
  import { useStore } from 'vuex'
  import { message } from 'ant-design-vue'
  import useUtils from '@/plugins/utils'
  import { referenceTerm } from '@/http/api'
  import useTis from '@/components/right_toolbar/tabs/useTip'
  import EditTermModal from '@/components/right_toolbar/tabs/modal/editTerm.vue'
  import { InfoCircleOutlined, SearchOutlined ,QuestionCircleOutlined} from '@ant-design/icons-vue'
  const { del, apply } = useTis()
  const store = useStore()
  const { dateFormat } = useUtils()

  const disabled = computed(() => store.state.openEditSourceText)
  const state = reactive({
    termDetail: {},
    editTermVisible: false,
    topping: 1,
    delVisible: false,
  })

  //是否是特殊语种
  const isSpecialLanguageText= computed(()=>{
    return store.state.specialLanguageList.includes(store.state.taskDetail.sourceLangId) || store.state.specialLanguageList.includes(store.state.taskDetail.targetLangId)
  })

  const applyTerm = (item, type) => {
    //此处需要判断光标是否处于子节点
    apply(item, type)
    referenceTerm({ libraryId: item.libId, entryId: item.id })
  }

  const twinslatorMatch = computed(
    () => store.state.excelProject.twinslatorMatch
  )

  const taskDetail = computed(() => store.state.taskDetail)

  const checkedDetail = computed(() => {
    return store.state.checkedDetail
  })

  //搜索
  const onSearch = () => {
    //键盘enter直接进入，否侧要判断是点击搜索还是清空i
    store.dispatch('openSearchWord')
  }

  const applyCorpus = (item, type) => {
    apply(item, type)
    item.libId && referenceTerm({ libraryId: item.libId, entryId: item.id })
  }

  const editTerm = (item) => {
    if (!item.canEdit) {
      message.warning('您暂无权编辑该词条，请联系项目管理员')
      return
    }
    nextTick(() => {
      state.termDetail = item
      state.editTermVisible = true
    })
  }

  const delTerm = (item) => {
    if (!item.canEdit) {
      message.warning('您暂无权删除该词条，请联系项目管理员')
      return
    }
    nextTick(() => {
      state.termDetail = item
      state.delVisible = true
    })
  }

  const retranslation = (item) => {
    if (!store.state.taskDetail.isOpenTwinslator) {
      message.warning('项目经理已关闭孪生译员，暂无法使用哦！')
      return
    }
    if (!store.state.taskDetail.newTwinslatorId  || store.state.taskDetail.newTwinslatorId<0) {
      message.warning(
        '当前并未设置机翻引擎，无法进行术语重译功能，请先在翻译设置中设置引擎'
      )
      return
    }

    store.commit('modal/changeKey', {
      retranslationTermExplain: {
        visible: true,
        termSource: item.source,
        termTarget: item.target,
      },
    })
  }

  const toppingChange = (e) => {
    state.topping = e
    localStorage.setItem('topping', e)
    message.success('置顶成功')
  }

  onBeforeMount(() => {
    let topping = localStorage.getItem('topping') || 1
    state.topping = topping
  })
</script>

<style scoped lang="less">
  .more-tooltip {
    .more-tooltip-header {
      display: flex;
      margin-bottom: 16px;
    }
    .more-tooltip-content {
      color: #86909c;
      line-height: 20px;
      font-size: 12px;
    }
  }

  .termCorpus-input-search {
    margin-top: 12px;
    margin-bottom: 12px;
    ::v-deep(.ant-input-search) {
      border-radius: 16px;
      border-width: 0 !important;
      box-shadow: 0 2px 4px 0 rgba(5, 92, 156, 0.04);
    }
    //::v-deep(.ant-input-affix-wrapper):hover{
    //  border-width: 0;
    //}
  }

  .corpus-result {
    padding-bottom: 4px;
    .title {
      font-size: 13px;
      margin-bottom: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        font-weight: 600;
        color: #1c2129;
      }
      .right {
        .icon {
          position: relative;
          top: 1px;
          font-size: 16px;
          color: #86909c;
        }
        cursor: pointer;
        color: #c9cdd4;
      }
    }
    .corpus-result-content {
      display: flex;
      flex-wrap: wrap;
      .corpus-result-item {
        box-shadow: 0 2px 4px 0 rgba(5, 92, 156, 0.04);
        border-radius: 16px;
        background: #ffffff;
        padding: 8px 12px;
        width: 100%;
        margin-bottom: 8px;
        .matchRate {
          display: inline-block;
          text-align: center;
          padding: 0 4px;
          color: #73c9ea;
          margin-right: 4px;
          height: 20px;
          line-height: 18px;
          background: #ffffff;
          border-radius: 9px;
          border: 1px solid #dbf3fc;
        }
        .sourceDiff {
          color: #1c2129;
          ::v-deep(.mtdelete) {
            color: #f55c3f;
          }
          ::v-deep(.mtadd) {
            cursor: default;
            color: #00a4fa;
            text-decoration: underline;
          }
        }
        .source {
          font-size: 13px;
          margin-bottom: 8px;
        }
        .target {
          font-size: 13px;
          color: #86909c;
          margin-bottom: 8px;
        }
        .comment {
          font-size: 13px;
          margin-bottom: 8px;
          color: #c9cdd4;
        }
        .apply {
          display: flex;
          align-items: center;
          justify-content: space-between;
          word-break: break-all;
          .info {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .icon {
              position: relative;
              top: 1px;
              color: #86909c;
              //margin-left: auto;
            }
            .createUserName {
              max-width: 80px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }

  .term-result {
    padding-bottom: 4px;
    .title {
      font-size: 13px;
      margin-bottom: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        font-weight: 600;
        color: #1c2129;
      }
      .right {
        .icon {
          font-size: 16px;
          color: #86909c;
        }
        cursor: pointer;
        color: #c9cdd4;
      }
    }
    .term-result-content {
      display: flex;
      flex-wrap: wrap;
      .term-result-item {
        position: relative;
        padding: 8px 16px 8px 12px;
        //display: flex;
        background: #ffffff;
        box-shadow: 0 2px 4px 0 rgba(5, 92, 156, 0.04);
        border-radius: 16px;
        margin-bottom: 8px;
        margin-right: 8px;
        border: 1px transparent solid;
        &:hover {
          border-color: #d0e9ff;
        }
        //align-items: center;
        //flex-wrap: wrap;
        .source {
          color: #1c2129;
          font-size: 13px;
          //flex-shrink: 0;
          margin-right: 8px;
        }
        .target {
          font-size: 13px;
          color: #86909c;
          margin-right: 8px;
          //flex-shrink: 0;
          //flex-grow: 1;
          //word-break: break-all;
        }
        .comment {
          font-size: 12px;
          margin-right: 8px;
          color: #c9cdd4;
        }
        .more {
          cursor: pointer;
          z-index: 1;
          position: absolute;
          margin-left: auto;
          color: #86909c;
          right: 4px;
          bottom: 8px;
          //flex-shrink: 0;
        }
      }
    }
  }
  //术语提示
  .ai-result-tips {
    overflow-wrap: break-word;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    &.topping {
      flex-direction: column-reverse;
    }

    .term-insert-button {
      //cursor: pointer;
      ::v-deep(.ant-btn) {
        cursor: pointer;
        padding: 0px;
        line-height: 18px;
        height: auto;
        font-size: 12px;
        display: block;
        //width: 100%;
        text-align: left;
        white-space: inherit;
        word-break: break-all;
      }
    }
  }
</style>
