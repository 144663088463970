export default function initWindowFunc(){
    //赋值replaceAll 方法，保证某些低版本浏览器能够正常用
    if(!String.prototype.replaceAll){
      String.prototype.replaceAll = function(str1,str2){
        return this.replace(new RegExp(str1,"gm"),str2);
      }
    }
    if (!window.BroadcastChannel) {
        window.BroadcastChannel = function (){
            this.postMessage =()=>{

            }
            this.onmessage=()=>{

            }
            this.close=()=>{

            }
        }
    }
    //window赋值
    window.$getTargetDom = function (sentId){
        return document.getElementById(`target-${sentId}`) || null
    }
    window.$getSourceDom = function (sentId){
        return document.getElementById(`source-sent-${sentId}`) || null
    }
    window.$assignValueToSrc = function (placeholderHTML) {
        ///判断是老文档解析还是新文档解析？

        //给占位符图片重新赋值用于过滤转换器
        let testNode = document.getElementById("create-html-test-node");
        if (testNode) {
            testNode.innerHTML = placeholderHTML;
            let imgList = testNode.getElementsByTagName('img');
            for(let i = 0;i<imgList.length;i++) {
                //如果imgList.src 不存在需要主动赋值
                if (!imgList[i].src) {
                    //填充透明颜色照片
                    imgList[i].src = "/img/transparent.png"
                }
            }
            return testNode.innerHTML;
        } else {
            return placeholderHTML;
        }
    }
    window.$removeValueToSrc = function (placeholderHTML) {
      ///判断是老文档解析还是新文档解析？

      //给占位符图片重新赋值用于过滤转换器
      let testNode = document.getElementById("create-html-test-node");
      if (testNode) {
        testNode.innerHTML = placeholderHTML;
        let imgList = testNode.getElementsByTagName('img');
        for(let i = 0;i<imgList.length;i++) {
          //移除前端添加的src属性;
          if (imgList[i].src.includes("/img/transparent.png")) {
            imgList[i].removeAttribute('src');
          }
        }
        return testNode.innerHTML;
      } else {
        return placeholderHTML;
      }
    }
}
